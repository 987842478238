import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useState} from "react";

const EditModal = ({ open, setOpen, title, type, edit, number, previous }) => {

    const handleClose = () => {
        setOpen(false);
        setText('');
    };

    const [text, setText] = useState('');


    const update = () => {
        edit(text === '' ? previous : text, number - 1);
        handleClose();
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Edit ${type} ${number} for ${title}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Add a ${type} text and save it. This is used when a new song is to be added.`}
                    </DialogContentText>

                    {
                        previous ?
                            <TextField
                                disabled
                                margin="dense"
                                id={`previous-text`}
                                label={'previous'}
                                type="text"
                                fullWidth
                                variant="standard"
                                multiline
                                value={previous}
                            />
                            :
                            null
                    }


                    <TextField
                        autoFocus
                        margin="dense"
                        id={`${type}-text`}
                        label={type}
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        onChange={e => setText(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={update}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditModal;
