import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function CurrencySelect({ currencies, setCurrency, disabled }) {
    return (
        <Autocomplete
            id="country-select-demo"
            sx={{ width: 180 }}
            options={currencies}
            disabled={disabled}
            autoHighlight
            getOptionLabel={(option) => option.currencyCode}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.currencyCode} (FX={option.rate})
                </Box>
            )}
            renderInput={(params) => {
                setCurrency(params.inputProps.value)
                return <TextField
                    {...params}
                    label="Choose a currency"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            }}
        />
    );
}