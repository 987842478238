import React, {useEffect} from 'react';
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/nav/navbar";
import LandingPage from "./pages/LandingPage";
import EventsPage from "./pages/EventsPage";
import FinancePage from "./pages/FinancePage";
import AdvertPage from "./pages/AdvertPage";
import TrafficPage from "./pages/TrafficPage";
import LoggedInFooter from "./components/footer/logged-in-footer";
import DevtoolsPage from "./pages/DevtoolsPage";
import MonitoringPage from "./pages/MonitoringPage";
import {useDispatch} from "react-redux";
import {fetchAppVersionAsync} from "./features/api-info/api-infoSlice";
import NewsPage from "./pages/NewsPage";
import SongPage from "./pages/SongPage";
import SandBoxPage from "./pages/SandBoxPage";
import {useAuth} from "oidc-react";
import LoggedOutPage from "./pages/LoggedOutPage";
import {setRoles} from "./features/user/user-slice";

const JCRoutes = () => {
    const auth = useAuth();
    const dispatch = useDispatch();


    useEffect(() => {
        if (auth && auth.userData) {
            dispatch(fetchAppVersionAsync(auth.userData?.access_token));

            if (auth.userData?.profile["cognito:groups"]) {
                dispatch(setRoles(auth.userData?.profile["cognito:groups"]))
            }
        }
    }, [auth.userData])

    const landingPage =  auth && auth.userData ?
        <LandingPage/>
        :
        <LoggedOutPage/>

    return (
        <>
            {
                auth && auth.userData ?
                    <Navbar roles={[]}/>
                    :
                    null
            }

            <Routes>
                <Route path={'/'} element={landingPage}/>
                <Route path={'/loggedout'} element={<LoggedOutPage/>}/>
                <Route path={'/events'} element={<EventsPage/>}/>
                <Route path={'/finance'} element={<FinancePage/>}/>
                <Route path={'/advert'} element={<AdvertPage/>}/>
                <Route path={'/traffic'} element={<TrafficPage/>}/>
                <Route path={'/devtools'} element={<DevtoolsPage/>}/>
                {
                    process.env.NODE_ENV === 'development' ?
                        <Route path={'/sandbox'} element={<SandBoxPage/>}/>
                        :
                        null
                }

                <Route path={'/monitoring'} element={<MonitoringPage/>}/>
                <Route path={'/song'} element={<SongPage/>}/>
                <Route path={'/news'} element={<NewsPage/>}/>
            </Routes>
            {
                auth && auth.userData ?
                    <LoggedInFooter/>
                    :
                    null
            }
        </>
    );
}

export default JCRoutes;
