import {getRequest, postRequest} from "../../httpClient";

export async function createEvent(token, eventRequest) {
    const body = eventRequest.getJsonString();
    return  await postRequest(`/v2/event`, token, body)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            }
            throw resp
        })
        .catch(err => err);
}

export async function fetchEvents(token) {

    return  await getRequest(`/v2/event?numberPerPage=50`, token)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            }
            throw resp
        })
        .catch(err => err);
}

export async function fetchIntervalSchedules(token) {
    return  await getRequest(`/v2/event/interval-schedules`, token)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            }
            throw resp
        })
        .catch(err => err);
}
