import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchAppVersion} from "./apiInfoAPI";

const initialState = {
    apiVersion: ""
}

export const fetchAppVersionAsync = createAsyncThunk(
    'api-info/fetchVersion',
    async () => {
        return await fetchAppVersion()
    }
)

export const apiInfoSlice = createSlice({
    name: 'api-info',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppVersionAsync.fulfilled, (state, action) => {
                state.apiVersion = action.payload.version
            })
            .addCase(fetchAppVersionAsync.rejected, (state, action) => {
                state.apiVersion = "N/A"
            })
    }
})

export default apiInfoSlice.reducer;
