import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useState} from "react";

const AddChorusModal = ({ open, setOpen, title, addChorus, chorusNumber }) => {

    const handleClose = () => {
        setOpen(false);
        setChorusText('');
    };

    const [chorusText, setChorusText] = useState('');


    const update = () => {
        addChorus(chorusText);
        handleClose();
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Add chorus ${chorusNumber} for ${title}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a chorus text and save it. This is used when a new song is to be added.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="verse-text"
                        label="verse"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        onChange={e => setChorusText(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={update}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddChorusModal;
