import { configureStore } from '@reduxjs/toolkit';
import apiInfoReducer from '../features/api-info/api-infoSlice';
import eventsReducer from '../features/events/eventSlice'
import userReducer from '../features/user/user-slice'

export const store = configureStore({
  reducer: {
    events: eventsReducer,
    apiInfo: apiInfoReducer,
    user: userReducer
  },
});
