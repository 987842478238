import React from "react";
import {Container, createTheme, ThemeProvider} from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});

const SuccessMessage = ({ message, variant, component, action, performAction, actionMessage }) => (
    <ThemeProvider theme={theme}>
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container maxWidth="sm" sx={{ mt: '3rem'}}>
                <Stack spacing={4} alignItems={"center"} display={"flex"}>
                    <Typography variant={variant} component={component} color={"info"}>
                        {message}
                    </Typography>

                    {
                        action ?
                            <Button onClick={performAction} variant={"contained"} color={"primary"} sx={{
                                width: 200
                            }}>
                                {actionMessage}
                            </Button>
                            :
                            null
                    }
                </Stack>

            </Container>
        </div>
    </ThemeProvider>
);

export default SuccessMessage;