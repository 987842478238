import React, {useEffect, useState} from "react";
import AdvertHeader from "../components/advert/advert-header";
import AdvertDetailContainer from "../components/advert/advert-detail-container";
import {deleteRequest, FAILED, getRequest, LOADING, putRequest} from "../httpClient";
import Loading from "../components/utils/loading";

import ErrorMessage from "../components/utils/error-message";
import UploadLandingPageModal from "../components/advert/modals/upload-landingpage-modal";
import {useAuth} from "oidc-react";

const AdvertPage = () => {

    const auth = useAuth();

    const [advertStatus, setAdvertStatus] = useState({});
    const [status, setStatus] = useState(LOADING);
    const [createModal, setCreateModal] = useState(false);

    const loadStatus = token => {
        getRequest(`/v1/landingpage/status`, token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw resp;
            })
            .then(data => {
                setAdvertStatus(data);
                setStatus('')
            })
            .catch(err => {
                setStatus(FAILED)
                console.log(err);
            });
    }

    const changeStatus = () => {
        setStatus(LOADING);
        putRequest(`/v1/landingpage/status`, auth?.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    loadStatus(auth?.userData?.access_token);
                }
            })
    }

    const changeDetailStatus = id => {
        putRequest(`/v1/landingpage/detail/${id}`, auth?.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    loadStatus(auth?.userData?.access_token);
                }
            })
    }

    const deleteDetail = id => {
        deleteRequest(`/v1/landingpage/detail/${id}`, auth?.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    loadStatus(auth?.userData?.access_token);
                }
            })
    }

    useEffect(() => {
        loadStatus(auth?.userData?.access_token)
    }, [auth.userData])

    return (
        <div>
            <AdvertHeader/>
            {
                status === LOADING ?
                    <Loading message={'loading advert data...'}/>
                    :
                    status === FAILED ?
                        <ErrorMessage
                            component={'div'}
                            variant={'h6'}
                            message={'Could not load advert information at this time. Please reload this page after a few minutes'}
                            action
                            actionMessage={'Reload'} performAction={() => window.location.reload()}/>
                        :
                    <AdvertDetailContainer
                        advertStatus={advertStatus}
                        changeStatus={changeStatus}
                        changeDetailStatus={changeDetailStatus} deleteDetail={deleteDetail} setOpenCreateModal={setCreateModal}/>
            }

            <UploadLandingPageModal setOpen={setCreateModal} open={createModal}/>
        </div>
    )
}

export default AdvertPage;