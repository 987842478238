import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useState} from "react";

const AddVerseModal = ({ open, setOpen, title, verses, addVerse, verseNumber }) => {

    const handleClose = () => {
        setOpen(false);
        setVerseText('');
    };

    const [verseText, setVerseText] = useState('');


    const update = () => {
        addVerse(verseText);
        handleClose();
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Add verse ${verseNumber} for ${title}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a verse text and save it. This is used when a new song is to be added.
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="verse-text"
                        label="verse"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        onChange={e => setVerseText(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={update}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddVerseModal;
