import React, {useEffect, useState} from "react";

import FinanceHeader from "../components/finance/finance-header";
import FinanceTable from "../components/finance/finance-table";
import {FAILED, getRequest, LOADING, SUCCESS} from "../httpClient";
import Loading from "../components/utils/loading";
import ErrorMessage from "../components/utils/error-message";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../components/utils/security-utils";

const rowPerPageOptionsConfig = [10,20,40];

const FinancePage = () => {

    const [data, setData] = useState({});
    const [status, setStatus] = useState(LOADING);
    const [events, setEvents] = useState([]);
    const [reloadData, setReloadData] = useState(rowPerPageOptionsConfig[0]);
    const [currentPage, setCurrentPage] = useState(0);
    const auth = useAuth();

    const loadEvents = (page, numberPerPage = 10, lastItemId = "") => {
        setCurrentPage(page)
        let expectedSize = (page+1) * numberPerPage;
        if (expectedSize > data.total) {
            expectedSize = data.total;
        }

        if (expectedSize > events.length) {
            load(numberPerPage, data.lastItemId);
        }

        const updatedData = {
            ...data,
            pageNumber: page
        }

        setData(updatedData);
    }

    const load = (numberPerPage, lastItemId = "") => {
        setStatus(LOADING)
        let token = auth?.userData?.access_token;
        let url = `/v2/finance?numberPerPage=${numberPerPage}`;
        if (lastItemId !== "") {
            url = `${url}&lastItemId=${lastItemId}`
        }
        getRequest(url, token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json()
                }
                throw resp
            })
            .then(response => {
                setData(response)
                let updatedEvents = [...events].concat(response.data);
                setEvents(updatedEvents);
                setStatus(SUCCESS)
            })
            .catch(err => setStatus(FAILED))
    }

    const freshReload = (numberPerPage, lastItemId = "") => {
        if (events.length === data.total) {
            const updatedData = {
                ...data,
                data: events,
                lastItemId: lastItemId,
                numberPerPage: numberPerPage
            }

            setData(updatedData);
            return;
        }

        let token = auth?.userData?.access_token;
        setStatus(LOADING)
        let url = `/v2/finance?numberPerPage=${numberPerPage}`;

        getRequest(url, token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json()
                }
                throw resp
            })
            .then(response => {
                setData(response)

                setEvents(response.data);
                setStatus(SUCCESS)
            })
            .catch(err => setStatus(FAILED))
    }

    useEffect(() => {
        if (isAuthenticated(auth)) {
            freshReload(reloadData, data.lastItemId);
        }
    }, [reloadData, auth.userData])

    const view = <>
        {
            status === LOADING ?
                <Loading message={'loading finance data...'}/>
                : status === SUCCESS && data !== null ?
                    <FinanceTable data={data} loadEvents={loadEvents} events={events ? events: []} freshReload={freshReload} setReloadData={setReloadData} currentPage={currentPage}/>
                    :
                    <ErrorMessage
                        component={'div'}
                        variant={'h6'}
                        message={'Could not load finance information at this time. Please reload this page after a few minutes'}
                        action
                        actionMessage={'Reload'} performAction={() => window.location.reload()}/>
        }
    </>

    return <div>
        <FinanceHeader/>
        {view}

    </div>
}

export default FinancePage;
