import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {InfoRounded, Key, Summarize, Visibility} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

import {CircularProgress, ListItemIcon, ListSubheader} from "@mui/material";
import Box from "@mui/material/Box";
import {getRequest} from "../../httpClient";
import FinanceEditMenu from "./finance-edit-menu";
import AddMoneyCollectedModal from "./modals/money-collected/add-money-collected-modal";
import DeleteMoneyCollectedModal from "./modals/money-collected/delete-money-collected-modal";
import {isAuthenticated} from "../utils/security-utils";
import {useAuth} from "oidc-react";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EventViewDialogContainer = ({ data, selectedIds }) => {
    const [open, setOpen] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState({});

    const [showAddMoneyModal, setShowAddMoneyModal] = useState(false);
    const [showDeleteMoneyModal, setShowDeletMoneyModal] = useState(false);
    const [events, setEvents] = useState(data);


    const handleShowAddMoneyModal = () => {
        setShowDeletMoneyModal(false)
        setShowAddMoneyModal(true);
    }

    const handleShowDeleteMoneyModal = () => {
        setShowAddMoneyModal(false);
        setShowDeletMoneyModal(true)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const noOfEvents = selectedIds.length;

    const auth = useAuth();

    const loadSelectedEvents = token => {
        getRequest(`/v2/finance/by-ids?pids=${selectedIds.toString()}`, token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw resp
            })
            .then(d => {
                let head = {}
                d.forEach((event) => {
                    head = {
                        ...head,
                        [event.pid] : event
                    }
                });
                setEvents(head);

                if (selectedEvent.pid) {
                    setSelectedEvent(head[selectedEvent.pid])
                }
            })
    }

    useEffect(() => {
        if (isAuthenticated(auth)) {
            loadSelectedEvents(auth?.userData?.access_token);
        }
    }, [auth.userData, selectedIds]);

    const shouldBeDisabled = event => {
        let today = new Date();
        today.setHours(0,0,0,0);

        let eventDate = new Date(event.date);
        eventDate.setHours(0,0,0,0);

        return eventDate > today;
    }

    return (
        <div>
            <Tooltip title="See">
                <IconButton onClick={handleClickOpen}>
                    <Visibility color={"success"}/>
                </IconButton>
            </Tooltip>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {`Viewing ${noOfEvents} event${noOfEvents > 1 ? "s" : ''}`}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            close
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    {
                        selectedIds.map((id, i) =>
                            <div key={i + new Date().getTime()}>
                                {
                                    events[id] ?
                                        <>
                                            <ListSubheader>{`${events[id]?.name} - ${events[id].date}`}</ListSubheader>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Key color={'error'}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Service Id" secondary={id}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <Summarize color={'primary'}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Total (SEK)" secondary={new Intl.NumberFormat('se-SE', { style: 'currency', currency: 'SEK' }).format(events[id].amountCollected.totalInSek)}/>
                                                <ListItemText primary={"Date recorded"} secondary={events[id].amountCollected.dateCollected ? events[id].amountCollected.dateCollected : 'N-A'}/>
                                                <ListItemText primary={"Recorded by"} secondary={events[id].recordedBy ? events[id].recordedBy : 'N-A'}/>
                                            </ListItem>

                                           <div style={{ marginTop: '3rem'}}/>

                                            {
                                                events[id].amountCollected.moneyCollected.length > 0 ?
                                                    <>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <InfoRounded color={"success"}/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={'Money collected details'}/>
                                                        </ListItem>
                                                        {
                                                            events[id].amountCollected.moneyCollected.map((mc, i) => <ListItem key={i+new Date().getTime()}>
                                                                <ListItemText secondary={i + 1}/>
                                                                <ListItemText primary={'Type'} secondary={<span style={{ color: '#1976d2'}}>{mc.type === 'Offering' ? 'Offr..' : `${mc.type}`}</span>}/>
                                                                <ListItemText primary={'Currency'} secondary={<span style={{ color: '#d32f2f'}}>{mc.currencyCode}</span>}/>
                                                                <ListItemText primary={`Total (${mc.currencyCode})`} secondary={<span style={{ color: '#2e7d32'}}>{new Intl.NumberFormat().format(mc.totalForOriginalCurrency)}</span>}/>

                                                                <ListItemText primary={`No. of currency`} secondary={<span style={{ color: '#b2102f'}}>{mc.noOfCurrency}</span>}/>
                                                                <ListItemText primary={`Denomination`} secondary={<span style={{ color: '#357a38'}}>{mc.denomination}</span>}/>
                                                                <ListItemText primary={`Total for SEK`} secondary={new Intl.NumberFormat('se-SE', { style: 'currency', currency: 'SEK' }).format(mc.currencyCode === 'SEK' ? mc.totalForOriginalCurrency : mc.totalForSEK)}/>
                                                            </ListItem>)
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <InfoRounded color={"success"}/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={'Money collected details'}/>
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText primary={'This event has no recorded money information'} secondary={'you can use the speed dial button to add new information about money collected.'}/>
                                                        </ListItem>
                                                    </>

                                            }

                                            <FinanceEditMenu event={events[id]}
                                                           handleShowAddMoneyModal={handleShowAddMoneyModal}
                                                           handleShowDeleteMoneyModal={handleShowDeleteMoneyModal}
                                                             setSelectedEvent={setSelectedEvent} disabled={shouldBeDisabled(events[id])}
                                            />
                                            <Divider/>
                                        </>
                                        :
                                        <ListItem>
                                            <Box sx={{display: 'flex'}}>
                                                <CircularProgress/>
                                            </Box>
                                        </ListItem>
                                }

                            </div>
                        )

                    }
                </List>
            </Dialog>
            <>
                <AddMoneyCollectedModal event={selectedEvent} open={showAddMoneyModal} setOpen={setShowAddMoneyModal} reload={loadSelectedEvents}/>
                <DeleteMoneyCollectedModal event={selectedEvent} open={showDeleteMoneyModal} setOpen={setShowDeletMoneyModal} reload={loadSelectedEvents}/>
            </>
        </div>
    );
}

export default EventViewDialogContainer;
