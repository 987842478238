export class EventRequest {
    constructor(date, name, recurring = false, intervalSchedule, startTime, endTime, intervalRate) {
        this.date = date;
        this.name = name;
        this.recurring = recurring;
        this.intervalRate = intervalRate;
        this.startTime = startTime;
        this.endTime = endTime;
        this.intervalSchedule = intervalSchedule;
    }

    getJsonString = () => {
        return JSON.stringify(this)
    }
}

