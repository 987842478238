import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {Container, createTheme, CssBaseline, ThemeProvider, Tooltip, useTheme} from "@mui/material";
import moment from "moment";
import Typography from "@mui/material/Typography";
import {Add} from '@mui/icons-material'

import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from "@mui/material/IconButton";
import CreateEventModal from "../events/modals/create-event-modal";
import {useEffect, useState} from "react";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(','),
    },
});

const ComingEventsCalendar = ({ officer, events, reload }) => {

    const [value, setValue] = React.useState(new Date());
    const [eventForDay, setEventForDay] = React.useState([]);

    const currentTheme = useTheme();
    const matches = useMediaQuery(currentTheme.breakpoints.up('sm'))

    const handleChange = newValue => {
        let date = moment(newValue).format('YYYY-MM-DD');
        let event = events.filter(event => moment(event.date).format('YYYY-MM-DD') === date);
        setEventForDay(event);
        setValue(newValue);
    };

    useEffect(() =>  {
        let date = moment(value).format('YYYY-MM-DD');
        let evForDay = events.filter(event => event.date === date);
        setEventForDay(evForDay);
    }, [events]);

    const [createModal, setCreateModal] = useState(false);
    return (
        <ThemeProvider theme={theme}>
            <div style={{textAlign: 'center'}}>
                <CssBaseline/>
                <Container maxWidth="sm" sx={{mt: matches ? '3rem' : '1.5rem'}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            orientation={matches ? "landscape" : "portrait"}
                            openTo="day"
                            value={value}
                            toolbarTitle={<div>
                                {
                                    eventForDay.length > 0 ?
                                        <>
                                            {
                                                eventForDay.map((event, i) => <div style={{ marginBottom: '.5rem'}} key={i}><Typography
                                                    color={'primary'} component="div"
                                                    variant="h6">{event.name}</Typography>
                                                    <Typography component="div" sx={{textTransform: 'lowercase'}}
                                                                color={'error'}
                                                                variant="h6">{`${event.startTime} - ${event.endTime}`}</Typography>
                                                </div>)
                                            }
                                        </>
                                        :
                                        <Typography component="div" variant="h6" color={'text.primary'}>
                                            NO ACTIVITIES
                                            {
                                                officer ?
                                                    <Tooltip title={'create event'}>
                                                        <IconButton color={"success"} onClick={() => setCreateModal(true)}>
                                                            <Add fontSize={"large"}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    null
                                            }

                                        </Typography>
                                }
                            </div>}
                            onChange={(newValue) => handleChange(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Container>
            </div>
            <>
                <CreateEventModal open={createModal} setOpen={setCreateModal} reloadForCalendar reload={reload}/>
            </>
        </ThemeProvider>
    );
}

export default ComingEventsCalendar;