import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useEffect, useState} from "react";
import {fetchEventsAsync, fetchIntervalSchedulesAsync } from "../../../features/events/eventSlice";
import {useDispatch, useSelector} from "react-redux";
import EventTimeSelector from "../event-time-selector";
import IntervalSettings from "../interval-settings";
import {EventRequest} from "../../../model/event";
import moment from "moment";
import {FAILED, LOADING, postRequest, SUCCESS} from "../../../httpClient";
import Typography from "@mui/material/Typography";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../../utils/security-utils";

const CreateEventModal = ({ open, setOpen, reload, reloadForCalendar }) => {

    const [date, setDate] = useState(new Date());
    const [name, setName] = useState('');
    const [recurring, setRecurring] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [intervalRate, setIntervalRate] = useState(0);
    const [intervalSchedule, setIntervalSchedule] = useState('');

    const [nameError, setNameError] = useState(false);
    const [intervalScheduleError, setIntervalScheduleError] = useState(false);
    const [intervalRateError, setIntervalRateError] = useState(false);

    const [status, setStatus] = useState('');

    const { intervals } = useSelector(state => state.events)

    const dispatch = useDispatch();
    const auth = useAuth();

    useEffect(() => {
        dispatch(fetchIntervalSchedulesAsync(auth?.userData?.access_token))
    }, []);

    useEffect(() => {
        let today = new Date(startTime.valueOf());
        today.setHours(today.getHours() + 1);
        setEndTime(today)
    }, [startTime]);

    const clearErrors = () => {
        setIntervalRateError(false);
        setIntervalScheduleError(false);
        setNameError(false);
    }

    const handleClose = () => {
        setOpen(false);
        clearErrors();
        setName('');
        setIntervalRate(0);
        setIntervalSchedule('');
        setRecurring(false);
    };

    const create = () => {
        clearErrors();
        let errorList = [];

        if (name === '') {
            errorList = [...errorList, 'Event name'];
            setNameError(true);
        }

        if (recurring) {
            if (intervalSchedule === '') {
                errorList = [...errorList, 'intervalSchedule'];
                setIntervalScheduleError(true);
            }

            if (intervalRate < 1) {
                errorList = [...errorList, 'intervalRate'];
                setIntervalRateError(true);
            }
        }

        if (errorList.length > 0) {
            return;
        }

        let _endTime = moment(endTime).format('HH:mm:00');
        let _startTime = moment(startTime).format('HH:mm:00');
        let _date = moment(date).format('YYYY-MM-DD');

        let eventRequest = new EventRequest(_date,name, recurring, intervalSchedule, _startTime, _endTime, intervalRate).getJsonString();

        if (!isAuthenticated(auth)) {
            return;
        }

        setStatus(LOADING);
         let token = auth?.userData?.access_token;

        postRequest(`/v2/event`, token, eventRequest)
            .then(resp => {
                if (resp.ok) {
                    setStatus(SUCCESS);
                    dispatch(fetchEventsAsync(token));
                    if (reloadForCalendar) {
                        reload();
                    }
                    handleClose();
                }
                throw resp;
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Create a new event`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You can create a new event. Fill in all the relevant information upon creating, you will be directed to the events page.
                    </DialogContentText>

                    <div style={{ marginBottom: '1rem'}}/>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Event name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        sx={{
                            mb: '1rem'
                        }}
                        onChange={e => setName(e.target.value)}
                        error={nameError}
                    />

                    <EventTimeSelector setStartTime={setStartTime} setEndTime={setEndTime} setDate={setDate} date={date} startTime={startTime} endTime={endTime}/>

                    <div style={{ marginBottom: '1rem'}}/>
                    <IntervalSettings
                        recurring={recurring}
                        schedules={intervals}
                        setRecurring={setRecurring}
                        setIntervalRate={setIntervalRate}
                        setIntervalSchedule={setIntervalSchedule} intervalScheduleError={intervalScheduleError} intervalRateError={intervalRateError}/>

                    {
                        status === FAILED ?
                            <Typography variant={'h5'} component={"div"} color={'error'}>
                                Could not create request at this time..please try again later
                            </Typography>
                            : null
                    }
                </DialogContent>
                <DialogActions>
                    <Button size={"large"} onClick={handleClose}>Cancel</Button>
                    <Button size={"large"} onClick={create}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CreateEventModal;
