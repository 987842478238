import React, {useEffect, useState} from "react";
import TrafficHeader from "../components/traffic/traffic-header";
import {deleteRequest, FAILED, getRequest, LOADING, SUCCESS} from "../httpClient";
import Loading from "../components/utils/loading";
import ErrorMessage from "../components/utils/error-message";
import TrafficDetailContainer from "../components/traffic/traffic-detail-container";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../components/utils/security-utils";

const TrafficPage = () => {

    const [latestMap, setLatestMap] = useState({});
    const [status, setStatus] = useState(LOADING);
    const [deleteStatus, setDeleteStatus] = useState('');
    const auth = useAuth();

    const loadMap = () => {
        getRequest(`/v1/traffic`, auth?.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw resp
            })
            .then(data => {
                setLatestMap(data);
                setStatus('');
            })
            .catch(err => {
                setStatus(FAILED);
                console.log(err);
            })
    };

    const deleteMap = () => {
        let token = auth?.userData?.access_token;
        setDeleteStatus(LOADING);

        deleteRequest(`/v1/traffic`, token)
            .then(resp => {
                if (resp.ok) {
                    setDeleteStatus(SUCCESS);
                    setTimeout(() => setDeleteStatus(''), 6000);
                    return;
                }
                throw resp
            })
            .then(() => loadMap(token))
            .catch(err => {
                setDeleteStatus(FAILED);

                setTimeout(() => setDeleteStatus(''), 6000);
                console.log(err);
            })
    };

    useEffect(() => {
        if (isAuthenticated(auth)) {
            loadMap();
        }
    }, [auth.userData]);

    return (
        <div>
            <TrafficHeader/>

            {
                status === LOADING ?
                    <Loading message={'loading traffic data...'}/>
                    :
                    status === FAILED ?
                        <ErrorMessage
                            component={'div'}
                            variant={'h6'}
                            message={'Could not load traffic information at this time. Please reload this page after a few minutes'}
                            action
                            actionMessage={'Reload'} performAction={() => window.location.reload()}/>
                        :
                        <TrafficDetailContainer latestMap={latestMap} deleteLatestMap={deleteMap} deleteStatus={deleteStatus} loadMap={loadMap}/>
            }
        </div>
    )
}
export default TrafficPage;