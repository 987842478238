import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useState} from "react";
import {EventUpdateRequest} from "../../../model/event-update-request";
import {putRequest} from "../../../httpClient";
import {fetchEventsAsync} from "../../../features/events/eventSlice";
import {useDispatch} from "react-redux";
import {isAuthenticated} from "../../utils/security-utils";
import {useAuth} from "oidc-react";
import moment from "moment";

const AttendanceUpdateModal = ({ open, setOpen, event, reload }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const [men,setMen] = useState(event.men);
    const [women, setWomen] = useState(event.women);
    const [children, setChildren] = useState(event.children);

    const auth = useAuth();
    const dispatch = useDispatch();

    const update = () => {
        if (!isAuthenticated(auth)) {
            return;
        }

        let token = auth?.userData?.access_token;

        let request = new EventUpdateRequest().buildFromEventDTO(event);

        request.men = Number(men);
        request.women = Number(women);
        request.children = Number(children);

        putRequest(`/v2/event/attendance`, token, request.getJsonString())
            .then(resp => {
                if (resp.ok) {
                   handleClose();
                    reload(token)
                    dispatch(fetchEventsAsync(token))
                }
                throw resp;
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Update attendance for ${event.name}(${moment(event.date).format("Do MMMM YYYY")})`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update the number of attendees of this service. You should only input for the given fields and the system will calculate a total.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="men"
                        label="no of men"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={e => setMen(e.target.value)}
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="women"
                        label="no of women"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={e => setWomen(e.target.value)}
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="children"
                        label="no of children"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={e => setChildren(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={update}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AttendanceUpdateModal;
