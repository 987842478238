import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Delete, Edit} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import EditChorusModal from "../modal/edit-chorus-modal";

const ITEM_HEIGHT = 48;

const ChorusMenu = ({chorus, song, searchClient }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedChorus, setSelectedChorus] = useState({});

    useEffect(() => {
        let selected = song.choruses.find(s => s.id === chorus.id);
        setSelectedChorus(selected);
    }, [chorus, song]);


    const handleOpenEditModal = () => {
        setOpenDeleteModal(false);
        setOpenEditModal(true);
        handleClose();
    }
    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={handleOpenEditModal}>
                    <Stack direction={"row"} spacing={6}>
                        <div>
                            edit
                        </div>
                        <div>
                            <Edit color={"success"} fontSize={"small"}/>
                        </div>
                    </Stack>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Stack direction={"row"} spacing={6}>
                        <div>
                            delete
                        </div>
                        <div>
                            <Delete color={"error"} fontSize={"small"}/>
                        </div>
                    </Stack>
                </MenuItem>
            </Menu>

            <EditChorusModal chorus={selectedChorus} song={song} open={openEditModal} setOpen={setOpenEditModal} searchClient={searchClient}/>
        </div>
    );
}

export default ChorusMenu;