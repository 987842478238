import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import {Add, Remove} from "@mui/icons-material";
import Stack from "@mui/material/Stack";

const ItemForm = ( { addItem, index, removeItem, item }) => {
    const [responsible, setResponsible] = useState(item.responsible);
    const [nameOfItem, setNameOfItem] = useState(item.nameOfItem);
    const [duration, setDuration] = useState(item.duration);

    const handleAddItem = () => {
        const item = {
            responsible,
            nameOfItem,
            duration,
            added: false
        }

        addItem(item);
    }

    const handleRemoveItem = () => {
        const item = {
            responsible,
            nameOfItem,
            duration
        }

        setNameOfItem('');
        setDuration('');
        setResponsible('');
        removeItem(item);
    }

    return (
        <Stack direction={'row'} spacing={4}>
            <TextField
                autoFocus
                id="order_item"
                label="Order item"
                type="text"
                variant="standard"
                onChange={e => setNameOfItem(e.target.value)}
                disabled={item.added}
            />

            <TextField
                margin="dense"
                id="order_responsible"
                label="Responsible"
                type="text"
                variant="standard"
                onChange={e => setResponsible(e.target.value)}
                disabled={item.added}
            />

            <TextField
                margin="dense"
                id="duration"
                label="Duration"
                type="number"
                variant="standard"
                onChange={e => setDuration(e.target.value)}
                disabled={item.added}
            />

            <div>
                <IconButton sx={{ top: '.5rem'}} onClick={handleAddItem} disabled={item.added || nameOfItem === '' || duration <= 0 || responsible === ''}>

                    <Add color={ 'primary'}/>
                </IconButton>

                <IconButton sx={{ top: '.5rem'}} onClick={handleRemoveItem}>
                    <Remove color={'warning'}/>
                </IconButton>
            </div>
        </Stack>
    )
}

export default ItemForm;