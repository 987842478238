import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import OrderOfServiceForm from "./order-of-service-form";
import {FAILED, LOADING, postRequest, SUCCESS} from "../../../httpClient";
import Stack from "@mui/material/Stack";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {isAuthenticated} from "../../utils/security-utils";
import {useAuth} from "oidc-react";


const initialItems = [{
    responsible: '',
    duration: 0,
    nameOfItem: '',
    added: false
}]
const AttendanceUpdateModal = ({ open, setOpen, event }) => {

    const auth = useAuth();
    const handleClose = () => {
        setOpen(false);
        setItems(initialItems);
    };

    const [items, setItems] = useState(initialItems);
    const [status, setStatus] = useState('');

    const addToItemList = item => {
        item.added = true;

        if (items.length === 1 && items[0].nameOfItem === '') {
            setItems([item, {
                responsible: '',
                duration: 0,
                nameOfItem: '',
                added: false
            }]);
            return;
        }

        let updated = [...items];
        updated.splice(items.length -1, 1);
        updated.push(item);

        updated = [...updated, {
            responsible: '',
            duration: 0,
            nameOfItem: '',
            added: false
        }]
        setItems(updated);
    }

    const removeFromItem = item => {
        if (items.length === 1) {
            handleClose();
            return;
        }

        if (items.length > 1) {
            let indexOfPrevious = 0;

            let current = [...items];
            current = current.filter((i, index) => {
                if (i.nameOfItem === item.nameOfItem) {
                    indexOfPrevious = index -1;
                }
                return i.nameOfItem !== item.nameOfItem
            })

            current[indexOfPrevious].added = false;
            setItems(current);
        }
    }

    const create = () => {
        if (!isAuthenticated(auth)) {
            return;
        }

        const cleanedData = items.filter((item) => item.nameOfItem !== '' || item.duration > 0 || item.responsible !== '' || item.added);

        if (cleanedData.length < 1) {
            console.log('found no valid order of service items.');
            return;
        }
        setStatus(LOADING);
        const revampedData = cleanedData.map(({ nameOfItem, duration, responsible}) => {
            return {
                nameOfItem: nameOfItem,
                duration: duration,
                responsible: responsible
            };
        })

        let token = auth?.userData?.access_token;

        postRequest(`/v1/event/${event.id}/order`, token, JSON.stringify(revampedData))
            .then(resp => {
                if (resp.ok) {
                    return resp.blob();
                }
                throw resp
            })
            .then(data => {
                const file = new Blob([data], {type: 'application/pdf'});
                const fileUrl = URL.createObjectURL(file);

                window.open(fileUrl, '_blank');
                setStatus(SUCCESS);
            })
            .catch(err => setStatus(FAILED));
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Create order of service for ${event.name}(${event.date})`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create order of service for this event. This order of service can downloaded directly or sent to all people who need it.
                    </DialogContentText>

                    {
                        status === LOADING ?
                                <Stack alignItems={"center"}>
                                    <div style={{ marginTop: '1rem'}}/>
                                    <CircularProgress color={"secondary"}/>
                                </Stack>
                            :
                            status === SUCCESS ?
                                <Stack>
                                    <Typography color={'secondary'} component={"div"} variant={'h5'} sx={{ mt: '1rem', textAlign: 'center'}}>
                                        Your file will be opened in a new tab.
                                    </Typography>
                                </Stack>
                                :
                                <OrderOfServiceForm items={items} addItem={addToItemList} removeItem={removeFromItem}/>
                    }

                </DialogContent>

                {
                    status === SUCCESS ?
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                        :
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={create}>Update</Button>
                        </DialogActions>
                }

            </Dialog>
        </div>
    );
}

export default AttendanceUpdateModal;
