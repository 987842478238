import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function IntervalSchedules ({ schedules, selectIntervalSchedule, error }) {
    const [schedule, setSchedule] = React.useState('');

    const handleChange = (event) => {
        setSchedule(event.target.value);
        selectIntervalSchedule(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Interval</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={schedule}
                    label="Interval"
                    onChange={handleChange}
                    error={error}
                >
                    {
                        schedules.map((interval, i) =>
                            <MenuItem key={i} value={interval.value}>
                                {interval.value}
                        </MenuItem>)
                    }
                </Select>
            </FormControl>
        </Box>
    );
}