import * as React from 'react';
import {Container, CssBaseline, ThemeProvider} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import generatedGitInfo from "../../generatedGitInfo.json";

import {theme} from "../../theme";
import moment from "moment";

export default function LoggedInFooter() {
    return (
        <ThemeProvider theme={theme}>
            <div>
                <CssBaseline />
                <Container maxWidth="lg" sx={{ mt: '3rem'}}>
                    <Box sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        bottom: 0, left: 0, right: 0
                    }}>
                        <Stack alignItems={"center"} spacing={1} direction={"column"} display={"flex"} alignContent={"center"}>
                            <Typography>
                                &copy; {moment(new Date()).format("YYYY")} rccgjesusconections
                            </Typography>

                            <Typography sx={{fontSize: '12px'}}>
                                {process.env.REACT_APP_VERSION}
                            </Typography>

                            {
                                process.env.NODE_ENV === 'development' ?
                                    <>
                                        <Typography>
                                            development branch: {generatedGitInfo.gitBranch}
                                        </Typography>
                                    </>
                                    :
                                    null
                            }
                        </Stack>

                    </Box>
                </Container>
            </div>

        </ThemeProvider>
    );
}
