import {getRequest} from "../../httpClient";

export async function fetchAppVersion() {
    return await getRequest(`/health`)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            }
            throw resp
        })
        .catch(err => err);
}
