import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useState} from "react";
import {EventUpdateRequest} from "../../../model/event-update-request";
import {putRequest} from "../../../httpClient";
import {fetchEventsAsync} from "../../../features/events/eventSlice";
import {useDispatch} from "react-redux";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../../utils/security-utils";
import moment from "moment";

const StreamLinkUpdateModal = ({ open, setOpen, event, reload }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const [link, setLink] = useState(event.streamingLink);

    const auth = useAuth();
    const dispatch = useDispatch();

    const update = () => {
        if (!isAuthenticated(auth)) {
            return;
        }

        let token = auth?.userData?.access_token;

        let request = new EventUpdateRequest().buildFromEventDTO(event);

        request.streamingLink = link;

        putRequest(`/v2/event/stream-link`, token, request.getJsonString())
            .then(resp => {
                if (resp.ok) {
                   handleClose();
                    reload(token)
                    dispatch(fetchEventsAsync(token))
                }
                throw resp;
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Update stream link for ${event.name}(${moment(event.date).format("Do MMMM YYYY")})`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update the live streaming link which is displayed on the main website. This link transmits can also be used as video replay for the service.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="link"
                        label="Link"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={e => setLink(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={update}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default StreamLinkUpdateModal;
