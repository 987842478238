import React from "react";
import {Container, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import Typography from "@mui/material/Typography";
import {MusicNote} from "@mui/icons-material";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});

const SongHeader = () => {
    return (
        <ThemeProvider theme={theme}>
            <div style={{ textAlign: 'center'}}>
                <CssBaseline />
                <Container maxWidth="sm" sx={{ mt: '3rem'}}>
                    <Typography component="div" variant="h4" sx={{ mb: '1rem', textTransform: 'uppercase'}} color={'info'}>
                        Song Manager <MusicNote fontSize={'large'} color={'info'}/>
                    </Typography>
                </Container>
            </div>
        </ThemeProvider>
    )
}

export default SongHeader;