import * as React from 'react';
import Stack from '@mui/material/Stack';

import ItemForm from "./item-form";


export default function OrderOfServiceForm({ items, addItem, removeItem }) {
    return (
        <div>
            <Stack direction="column" spacing={2}>
                {
                    items.map((item, i) => <ItemForm removeItem={removeItem} addItem={addItem} index={i} key={i} item={item}/> )
                }

            </Stack>
        </div>
    );
}