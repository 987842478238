import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../assets/logo.svg';
import {Fingerprint} from "@mui/icons-material";
import LoggedOutFooter from "../components/footer/logged-out-footer";
import {useAuth} from "oidc-react";


const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});

export default function LoggedOutPage() {

    const auth = useAuth();

    return (
        <ThemeProvider theme={theme}>
            <div>
                <div style={{ marginTop: '5rem'}}/>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ marginTop: '4rem'}}/>
                    <Avatar src={logo} sx={{ cursor: 'pointer', width: '100px', height: '100px' }} />
                    <div style={{ marginTop: '2rem'}}/>
                    <Typography component="h1" variant="h5">
                        Welcome back
                    </Typography>

                    <Typography component="h4" variant="h5">
                        you'll need to sign in to continue
                    </Typography>


                    <div style={{ marginTop: '2rem'}}/>
                    <Button size={"large"} variant={"contained"} color={'primary'} onClick={() => auth.signIn()}>
                        sign in <Fingerprint fontSize={"large"}/>
                    </Button>
                </Box>

            </div>
            <LoggedOutFooter/>
        </ThemeProvider>
    );
}