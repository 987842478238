import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Add, Delete } from '@mui/icons-material'


const actions = [
    { icon: <Delete color={'error'}/>, name: 'Delete money collected' },
    { icon: <Add color={"success"}/>, name: 'Add money collected' },
];

const FinanceEditMenu = ( { event, setSelectedEvent,handleShowAddMoneyModal, handleShowDeleteMoneyModal, disabled }) => {

    const handleOnHover = () => {
        setSelectedEvent(event);
    }

    const handleOnLeave = () => {
        setSelectedEvent({})
    }
    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
            <SpeedDial
                onMouseEnter={handleOnHover}
                onMouseLeave={handleOnLeave}
                ariaLabel="finance event menu"
                sx={{ position: 'absolute', bottom: 20, right: 16 }}
                icon={<SpeedDialIcon/>}
            >
                {
                    event.amountCollected.moneyCollected.length > 0 ?
                        <SpeedDialAction key={actions[0].name} icon={actions[0].icon} tooltipTitle={actions[0].name} onClick={handleShowDeleteMoneyModal}/>
                        :null
                }

                        <SpeedDialAction disabled={disabled} key={actions[1].name} icon={actions[1].icon} tooltipTitle={actions[1].name} onClick={handleShowAddMoneyModal}/>

            </SpeedDial>
        </Box>
    );
}

export default FinanceEditMenu;