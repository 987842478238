import React, {useEffect, useState} from "react";
import Shortcut from "../components/shortcut/shortcut";
import ComingEventsCalendar from "../components/coming-events/coming-events-calendar";

import {FAILED, getRequest, LOADING} from "../httpClient";
import Loading from "../components/utils/loading";
import ErrorMessage from "../components/utils/error-message";
import {useSelector} from "react-redux";
import {useAuth} from "oidc-react";

const LandingPage = () => {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [status, setStatus] = useState(LOADING);

    const { roles } = useSelector(state => state.user);
    const auth = useAuth();

    useEffect(() => {
        if (auth && auth.userData) {
            if (calendarEvents.length === 0) {
                loadCalendarEvents();
            }

        }
    }, [auth.userData]);

    const rolesString = roles.toString();

    const loadCalendarEvents = () => {
        getRequest(`/v2/event/calendar?numberPerPage=50&page=0`, auth.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw resp;
            })
            .then(data => {
                setCalendarEvents(data);
                setStatus('');
            })
            .catch(err => {
                console.log(err);
                setStatus(FAILED)
            })
    }


    return (
        <div>
            {
                status === LOADING ?
                    <Loading message={'loading calendar'}/>
                    :
                    status === FAILED ?
                        <ErrorMessage variant={'h6'}
                                      component={'div'}
                                      message={'could not load calendar.. please try again later'}
                                      action actionMessage={'reload'} performAction={() => window.location.reload()}/>
                        :
                        <ComingEventsCalendar events={calendarEvents ? calendarEvents : []} officer={rolesString.includes("officer")} reload={loadCalendarEvents}/>
            }
            <Shortcut roles={roles}/>
        </div>
    )
};
export default LandingPage;
