import React from "react";
import SongHeader from "../components/song/song-header";

import SongSearchClient from "../components/song/song-search-client";
import SongDialogContainer from "../components/song/song-dialog-container";
import CenterDiv from "../components/utils/center-div";
import {theme} from "../theme";
import {ThemeProvider} from "@emotion/react";

const SongPage = () => {

    return (
        <div>
            <SongHeader/>
                <ThemeProvider theme={theme}>
                    <CenterDiv>
                        <SongDialogContainer/>
                    </CenterDiv>
                </ThemeProvider>
                <SongSearchClient/>

        </div>
    )
}

export default SongPage;