import React from "react";
import algoliasearch from "algoliasearch";
import {
    InstantSearch,
    SearchBox,
    Hits,
    Pagination,
    Configure
} from 'react-instantsearch-dom';

import './song.css';
import SongCard from "./song-card";
import {theme} from "../../theme";
import {ThemeProvider} from "@emotion/react";
import {Container} from "@mui/material";

const searchClient = algoliasearch(
    window.ALGOLIA_APP_ID,
    window.ALGOLIA_API_KEY
);
const SongSearchClient = () => {
    return (
        <ThemeProvider theme={theme}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }}>
            <InstantSearch
                refresh
                indexName="songs"
                searchClient={searchClient}
            >
                <div style={{
                    textAlign: 'center'
                }}>
                    <SearchBox />
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row'
                }}>
                    <Hits hitComponent={(props) => <Hit searchClient={searchClient} {...props}/>}/>
                </div>

                <div>
                    <Container maxWidth="lg" sx={{ mt: '3rem'}}>
                    <Pagination />
                    </Container>
                </div>

                <Configure hitsPerPage={10}/>
            </InstantSearch>
        </div>
        </ThemeProvider>
    )
}

function Hit(props) {
    return (
        <SongCard song={props.hit} searchClient={props.searchClient}/>
    );
}



export default SongSearchClient;