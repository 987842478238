import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import { Traffic, Money } from '@mui/icons-material'

const actions = [
    { icon: <FileCopyIcon />, name: 'Events' },
    { icon: <Money />, name: 'Finance' },
    { icon: <SaveIcon />, name: 'Advert' },
    { icon: <PrintIcon />, name: 'Admin' },
    { icon: <ShareIcon />, name: 'Contact' },
    { icon: <Traffic />, name: 'Traffic' },
];

const Shortcut = ( { roles }) => {
    const stringRoles = roles.toString();

    if (roles.length === 0) {
        return null;
    }
    return (
        <Box sx={{ height: 100, transform: 'translateZ(0px)', flexGrow: 1, display: {xs: 'flex', md: 'none', lg: 'none', xl: 'none', sm: 'none'} }}>
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 100, right: 16 }}
                icon={<SpeedDialIcon/>}
            >

                {
                    stringRoles.includes('officer') ?
                        <SpeedDialAction key={actions[0].name} icon={actions[0].icon} tooltipTitle={actions[0].name}/>
                        : null
                }

                {
                    stringRoles.includes('finance') ?
                        <SpeedDialAction key={actions[1].name} icon={actions[1].icon} tooltipTitle={actions[1].name}/>
                        : null
                }

                {
                    stringRoles.includes('media') ?
                        <SpeedDialAction key={actions[2].name} icon={actions[2].icon} tooltipTitle={actions[2].name}/>
                        : null
                }

                {
                    stringRoles.includes('user') ?
                        <SpeedDialAction key={actions[3].name} icon={actions[3].icon} tooltipTitle={actions[3].name}/>
                        : null
                }

                {
                    stringRoles.includes('sysops') ?
                        <SpeedDialAction key={actions[4].name} icon={actions[4].icon} tooltipTitle={actions[4].name}/>
                        : null
                }
            </SpeedDial>
        </Box>
    );
}

export default Shortcut;