import React from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthProvider} from "oidc-react";
import JCRoutes from "./jc-routes";


const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});
const App = () => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const removeQueryParams = () => {
        const code = searchParams.get('code');

        if (code) {
            searchParams.delete('code');
            setSearchParams(searchParams);
        }

        const state = searchParams.get('state');

        if (state) {
            searchParams.delete('state');
        }

        setSearchParams(searchParams);
    };

    const oidcConfig = {
        authority: window.COGNITO_URL,
        clientId: window.COGNITO_CLIENT_ID,
        redirectUri: window.COGNITO_REDIRECT_URL,
        postLogoutRedirectUri: window.COGNITO_LOGGED_OUT_URL,
        autoSignIn: false,
        onSignIn: () => {
            navigate('/', { replace: true });
            removeQueryParams();
        },
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AuthProvider {...oidcConfig}>
                <JCRoutes/>
            </AuthProvider>
        </ThemeProvider>
    )
}

export default App;
