import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useEffect, useState} from "react";
import moment from "moment";
import {Switch} from "@mui/material";
import Stack from "@mui/material/Stack";
import {putRequest} from "../../../httpClient";
import {useAuth} from "oidc-react";

const NewItemUpdateModal = ({ open, setOpen, newsItem, reload }) => {

    const handleClose = () => {
        setOpen(false);
        setShow(false);
        setHeadline('');
        setContent('');
    };

    const [headline, setHeadline] = useState('');
    const [content, setContent] = useState('');
    const [show, setShow] = useState(false);

    const auth = useAuth();

    const update = () => {
        let token = auth.userData?.access_token;

        const body = {
            headline : headline === '' ? newsItem.headline : headline,
            content: content === '' ? newsItem.content : content,
            show
        }

        putRequest(`/v1/landingpage/news/${newsItem.id}`,token, JSON.stringify(body))
            .then(resp => {
                if (resp.ok) {
                    reload();
                    handleClose();
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => console.log(newsItem), [newsItem])
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Update news item (${newsItem.headline} - ${moment(newsItem.dateAdded).format('HH:mm:ss a')})`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update an existing news item. This news item is displayed on the main webpage as well as the admin landingpage.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="headline"
                        label={newsItem.headline}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={e => setHeadline(e.target.value)}
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="content"
                        label={newsItem.content}
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        onChange={e => setContent(e.target.value)}
                    />

                    <div style={{
                        textAlign: 'center',
                        textTransform: 'uppercase'
                    }}>
                        <Stack alignItems={"center"} direction={"row"}>
                            <span>off</span>
                            <Switch defaultChecked={newsItem.show} onChange={e => setShow(e.target.checked)}/>
                            <span>on</span>
                        </Stack>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={update}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default NewItemUpdateModal;
