import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import {Delete, Visibility, VisibilityOff} from "@mui/icons-material";

export default function DetailCard({ detail, changeDetailStatus, deleteDetail }) {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                component="img"
                height="100%"
                width="100%"
                image={detail.link}
                alt={detail.name}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {detail.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ wordBreak: 'break-all'}}>
                    {detail.link}
                </Typography>
            </CardContent>
            <CardActions>

                {
                    detail.show ?
                        <IconButton onClick={() => changeDetailStatus(detail.id)}>
                            <Visibility color={"success"}/>
                        </IconButton>
                        :
                        <IconButton onClick={() => changeDetailStatus(detail.id)}>
                            <VisibilityOff/>
                        </IconButton>
                }

                <IconButton size={"small"} onClick={() => deleteDetail(detail.id)}>
                    <Delete color={"error"}/>
                </IconButton>
            </CardActions>
        </Card>
    );
}