import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Visibility, Link, Female, Male, ChildCare, Share, Key } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

import {CircularProgress, ListItemIcon, ListSubheader} from "@mui/material";
import Box from "@mui/material/Box";
import EventEditMenu from "./event-edit-menu";
import {useEffect, useState} from "react";
import AttendanceUpdateModal from "./modals/attendance-update-modal";
import {getRequest} from "../../httpClient";
import CreateOrderOfServiceModal from "./modals/create-order-of-service-modal";
import UploadMp3Modal from "./modals/upload-mp3-modal";
import StreamLinkUpdateModal from "./modals/stream-link-update-modal";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../utils/security-utils";
import moment from "moment";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EventViewDialogContainer = ({ data, selectedIds }) => {
    const [open, setOpen] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState({});
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showStreamLinkModal, setShowStreamLinkModal] = useState(false);
    const [showOrderOfServiceModal, setShowOrderOfServiceModal] = useState(false);
    const [showUpdateAttendanceModal, setShowUpdateAttendanceModal] = useState(false);
    const [events, setEvents] = useState(data);

    const handleShowUploadModal = () => {
        setShowStreamLinkModal(false);
        setShowOrderOfServiceModal(false);
        setShowUpdateAttendanceModal(false);
        setShowUploadModal(true);
    }

    const handleShowOrderOfServiceModal = () => {
        setShowStreamLinkModal(false);
        setShowUpdateAttendanceModal(false);
        setShowUploadModal(false);
        setShowOrderOfServiceModal(true);
    }

    const handleShowStreamLinkModal = () => {
        setShowUpdateAttendanceModal(false);
        setShowUploadModal(false);
        setShowOrderOfServiceModal(false);
        setShowStreamLinkModal(true);
    }

    const handleShowUpdateAttendanceModal = () => {
        setShowUploadModal(false);
        setShowOrderOfServiceModal(false);
        setShowStreamLinkModal(false);
        setShowUpdateAttendanceModal(true);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const noOfEvents = selectedIds.length;

    const auth = useAuth();

    const loadSelectedEvents = token => {
        getRequest(`/v2/event/by-pids?pids=${selectedIds.toString()}`, token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw resp
            })
            .then(d => {
                let head = {}
                d.forEach(({pid, name, date, startTime, endTime,  children, men,
                                    women, streamingLink, messageLink}) => {
                    let e = {
                        name,pid, date, startTime, endTime, children, men, women, streamingLink, messageLink
                    }

                    head = {
                        ...head,
                        [pid] : e
                    }
                });
                setEvents(head);
            })
    }

    useEffect(() => {
        if (isAuthenticated(auth)) {
            loadSelectedEvents(auth?.userData?.access_token);
        }
    }, [auth.userData, selectedIds])

    return (
        <div>
            <Tooltip title="See">
                <IconButton onClick={handleClickOpen}>
                    <Visibility color={"success"}/>
                </IconButton>
            </Tooltip>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {`Viewing ${noOfEvents} event${noOfEvents > 1 ? "s" : ''}`}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            close
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    {
                        selectedIds.map((id, i) =>
                            <div key={i + new Date().getTime()}>
                                {
                                    events[id] ?
                                        <>
                                            <ListSubheader>{`${events[id]?.name} - ${moment(events[id].date).format("MMMM Do YYYY")}`}</ListSubheader>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Key color={'error'}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Service Id" secondary={id}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <Male color={'primary'}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Men" secondary={events[id].men}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <Female color={"secondary"}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Women" secondary={events[id].women}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <ChildCare color={"warning"}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Children" secondary={events[id].children}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <Link color={'info'}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Streaming link"
                                                              secondary={events[id].streamingLink ? events[id].streamingLink : "N/A"}/>
                                            </ListItem>

                                            {/*<ListItem>*/}
                                            {/*    <ListItemIcon>*/}
                                            {/*        <Share color={'primary'}/>*/}
                                            {/*    </ListItemIcon>*/}
                                            {/*    <ListItemText primary="Message link"*/}
                                            {/*                  secondary={events[id].messageLink ? events[id].messageLink : "N/A"}/>*/}
                                            {/*</ListItem>*/}
                                            <EventEditMenu event={events[id]}
                                                           setSelectedEvent={setSelectedEvent} selectedEvent={selectedEvent}
                                                           handleShowOrderOfServiceModal={handleShowOrderOfServiceModal}
                                                           handleShowStreamLinkModal={handleShowStreamLinkModal}
                                                           handleShowUpdateAttendanceModal={handleShowUpdateAttendanceModal}
                                                           handleShowUploadModal={handleShowUploadModal}
                                            />
                                            <Divider/>
                                        </>
                                        :
                                        <ListItem>
                                            <Box sx={{display: 'flex'}}>
                                                <CircularProgress/>
                                            </Box>
                                        </ListItem>
                                }

                            </div>
                        )

                    }
                </List>
            </Dialog>
            <>
                <AttendanceUpdateModal event={selectedEvent} open={showUpdateAttendanceModal}
                                       reload={loadSelectedEvents}
                                       setOpen={setShowUpdateAttendanceModal}/>

                <CreateOrderOfServiceModal event={selectedEvent} open={showOrderOfServiceModal} setOpen={setShowOrderOfServiceModal}/>

                <UploadMp3Modal event={selectedEvent} open={showUploadModal} setOpen={setShowUploadModal}/>

                <StreamLinkUpdateModal event={selectedEvent} open={showStreamLinkModal} setOpen={setShowStreamLinkModal} reload={loadSelectedEvents}/>
            </>
        </div>
    );
}

export default EventViewDialogContainer;
