import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {FAILED, getRequest, LOADING, SUCCESS} from "../../../../httpClient";
import Stack from "@mui/material/Stack";
import {CircularProgress, InputLabel, Select} from "@mui/material";
import Typography from "@mui/material/Typography";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import CenterDiv from "../../../utils/center-div";
import Switch from "@mui/material/Switch";
import {useAuth} from "oidc-react";

const types = ['Tithe', 'Financial'];

const GenerateReportModal = ({ open, setOpen }) => {

    const handleClose = () => {
        setOpen(false);
        setStatus('');
        setStartDate(new Date())
        setEndDate(new Date())
        setFormat('pdf');
    };

    const auth = useAuth();
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [format, setFormat] = useState('pdf');

    const handleChange = (event) => {
        setType(event.target.value);
    };

    const generateReport = () => {
        if (type === '') {
            return;
        }

        setStatus(LOADING)

        const start = moment(startDate).format("YYYY-MM-DD");
        const end = moment(endDate).format("YYYY-MM-DD");

        let headers = {
            start,
            end,
            format
        }

        let token = auth?.userData?.access_token;

        getRequest(`/v2/finance/${type.toLowerCase()}-report`, token, headers)
            .then(resp => {
                if (resp.ok) {
                    return resp.blob();
                }
                throw resp
            })
            .then(data => {
                if (format === 'pdf') {
                    const file = new Blob([data], {type: 'application/pdf'});
                    const fileUrl = URL.createObjectURL(file);

                    window.open(fileUrl, '_blank');
                }else {
                    let a = document.createElement("a")
                    let blobURL = URL.createObjectURL(data)
                    a.download = `report-${moment(startDate).format("YYYY-MM")}-${moment(endDate).format("YYYY-MM")}.xlsx`
                    a.href = blobURL
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                }
                setStatus(SUCCESS);
            })
            .catch(() => {
                setStatus(FAILED);
                setInterval(() => setStatus(''),6000);
            });
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogTitle>{`Generate reports`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Reports can be generated for financial purposes. Select tithe or a full financial report and this will open a new tab with the generated document.
                    </DialogContentText>

                    <div style={{ marginTop: '1rem'}}/>
                    {
                        status === LOADING ?
                            <Stack alignItems={"center"}>
                                <div style={{ marginTop: '1rem'}}/>
                                <CircularProgress color={"secondary"}/>
                            </Stack>
                            :
                            status === SUCCESS ?
                                <Stack>
                                    <Typography color={'primary'} component={"div"} variant={'h5'} sx={{ mt: '1rem', textAlign: 'center'}}>
                                        {`Report will be opened in a new tab.`}
                                    </Typography>
                                </Stack>
                                :
                                <div>
                                    <Stack direction={'row'} spacing={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                        <Box sx={{ minWidth: 200 }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="money-collected-type">Type</InputLabel>
                                                <Select
                                                    labelId="money-collected-type"
                                                    id="money-collected-type-select"
                                                    value={type}
                                                    label="Type"
                                                    onChange={handleChange}
                                                >
                                                    {
                                                        types.map((type) => <MenuItem key={type} value={type}>
                                                            {type}
                                                        </MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        {/*    TODO Add date pickers for start and end date. Then add the api call using window to open a new tab with the pdf file.*/}
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Box sx={{ minWidth: 200 }}>
                                                <DatePicker
                                                    label="Start Date"
                                                    openTo="year"
                                                    views={['year', 'month']}
                                                    value={startDate}
                                                    onChange={(newValue) => {
                                                        setStartDate(newValue)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />

                                            </Box>

                                            <Box sx={{ minWidth: 200 }}>

                                                <DatePicker
                                                    label="End Date"
                                                    openTo="year"
                                                    views={['year', 'month']}
                                                    value={endDate}
                                                    onChange={(newValue) => {
                                                        setEndDate(newValue)
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />

                                            </Box>

                                        </LocalizationProvider>
                                    </Stack>

                                    <CenterDiv>
                                        <div style={{
                                            textAlign: 'center',
                                            textTransform: 'uppercase',
                                            marginTop: '1rem'
                                        }}>
                                            <Stack alignItems={"center"} direction={"row"}>
                                                <span>excel</span>
                                                <Switch defaultChecked onChange={e => {
                                                    if (e.target.checked) {
                                                        setFormat('pdf')
                                                    }else {
                                                        setFormat('excel')
                                                    }
                                                }
                                                }/>
                                                <span>pdf</span>
                                            </Stack>
                                        </div>
                                    </CenterDiv>

                                </div>
                    }

                </DialogContent>

                {
                    status === SUCCESS ?
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                        :
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={generateReport}>generate</Button>
                        </DialogActions>
                }

            </Dialog>
        </div>
    );
}

export default GenerateReportModal;
