import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {Visibility, Key, Person, Book, VisibilityOff} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

import {CircularProgress, ListItemIcon, ListSubheader} from "@mui/material";
import Box from "@mui/material/Box";

import {useEffect, useState} from "react";
import {getRequest} from "../../httpClient";
import NewsEditMenu from "./news-edit-menu";
import NewItemUpdateModal from "./modals/news-item-update-modal";
import DeleteNewsItemModal from "./modals/delete-news-item-modal";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../utils/security-utils";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NewsViewDialogContainer = ({ data, selectedIds, loadNews }) => {
    const [open, setOpen] = React.useState(false);
    const [selectedNewsItem, setSelectedNewsItem] = React.useState({});

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleShowEditModal = () => {
        setShowDeleteModal(false);
        setShowEditModal(true);
    }

    const handleShowDeleteModal = () => {
        setShowEditModal(false);
        setShowDeleteModal(true);
    }

    const [news, setNews] = useState(data);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const noOfNewsItems = selectedIds.length;
    const auth = useAuth();

    const loadSelectedNewsItems = () => {
        getRequest(`/v1/landingpage/news/by-ids?ids=${selectedIds.toString()}`, auth?.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw resp
            })
            .then(d => {
                let head = {}
                d.forEach(({id, headline, content, author, dateAdded,  show }) => {
                    let e = {
                        id, headline, content, author, dateAdded, show
                    }

                    head = {
                        ...head,
                        [id] : e
                    }
                });
                setNews(head);
            })
    }

    useEffect(() => {
        if (isAuthenticated(auth)) {
            loadSelectedNewsItems(auth?.userData?.access_token);
        }
    }, [auth.userData, selectedIds])

    return (
        <div>
            <Tooltip title="See">
                <IconButton onClick={handleClickOpen}>
                    <Visibility color={"success"}/>
                </IconButton>
            </Tooltip>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {`Viewing ${noOfNewsItems} news item${noOfNewsItems > 1 ? "s" : ''}`}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            close
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    {
                        selectedIds.map((id, i) =>
                            <div key={i + new Date().getTime()}>
                                {
                                    news[id] ?
                                        <>
                                            <ListSubheader>{`${id}`}</ListSubheader>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <Key color={'error'}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Name" secondary={news[id]?.headline}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <Person color={'primary'}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Author" secondary={news[id].author}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <Book color={'success'}/>
                                                </ListItemIcon>
                                                <ListItemText primary="Content"
                                                              secondary={news[id].content ? news[id].content : "N/A"}/>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    {
                                                        news[id].show ?
                                                            <Visibility color={'secondary'}/>
                                                            :
                                                            <VisibilityOff color={'disabled'}/>
                                                    }
                                                </ListItemIcon>
                                                <ListItemText primary="Show"
                                                              secondary={news[id].show ? 'Is visible' : 'Not visible'}/>
                                            </ListItem>

                                            <NewsEditMenu newsItem={news[id]}
                                                          setSelectedNewsItem={setSelectedNewsItem}
                                                          handleShowEditModal={handleShowEditModal}
                                                          handleShowDeleteModal={handleShowDeleteModal}
                                            />
                                            <Divider/>
                                        </>
                                        :
                                        <ListItem>
                                            <Box sx={{display: 'flex'}}>
                                                <CircularProgress/>
                                            </Box>
                                        </ListItem>
                                }

                            </div>
                        )

                    }
                </List>
            </Dialog>
            <>
                <NewItemUpdateModal newsItem={selectedNewsItem} setOpen={setShowEditModal} open={showEditModal} reload={loadSelectedNewsItems}/>
                <DeleteNewsItemModal
                    newsItem={selectedNewsItem}
                    setOpen={setShowDeleteModal}
                    open={showDeleteModal}
                    reload={loadSelectedNewsItems}
                    loadNews={loadNews} closeDialog={() => setOpen(false)}/>
            </>
        </div>
    );
}

export default NewsViewDialogContainer;