import * as React from 'react';

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Container, CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";

export default function LoggedOutFooter() {
    return (
        <div>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: '3rem'}}>
                <Box sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'fixed', bottom: 0, left: 0, right: 0
                }}>
                    <Stack alignItems={"center"} spacing={2} direction={"row"} display={"flex"} alignContent={"center"}>
                        <Typography sx={{fontSize: '12px'}}>
                            {process.env.REACT_APP_VERSION}
                        </Typography>
                    </Stack>
                </Box>
            </Container>
        </div>

    );
}
