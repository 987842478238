import React, {useEffect, useState} from "react";

import ErrorMessage from "../components/utils/error-message";
import {FAILED, getRequest, LOADING} from "../httpClient";
import Loading from "../components/utils/loading";
import NewsHeader from "../components/news/news-header";
import NewsTable from "../components/news/news-table";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../components/utils/security-utils";

const NewsPage = () => {

    const [status, setStatus] = useState(LOADING);
    const [news, setNews] = useState([]);
    const auth = useAuth();

    const loadNews = () => {
        getRequest(`/v1/landingpage/news`,auth?.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }

                throw resp;
            })
            .then(data => {
                setNews(data);
                setStatus('');
            })
            .catch(err => {
                console.log(err);
                setStatus(FAILED);
            })
    }

    useEffect(() => {
        if (isAuthenticated(auth)) {
            if (news.length === 0) {
                loadNews();
            }
        }
    }, [auth.userData])

    if (status === LOADING) {
        return <div style={{
            marginTop: '3rem'
        }}>
            <Loading message={'one moment please'}/>
        </div>
    }
    return <div>
        <NewsHeader/>
        {
            status === '' ?
                <>
                    <NewsTable news={news} loadNews={loadNews}/>
                </>
                :
                <ErrorMessage message={'Could not get news at the moment..please reload this page at a later time'}
                              variant={'h6'}
                              component={'div'}
                              action
                              actionMessage={'reload'}
                              performAction={() => window.location.reload()}/>
        }

    </div>
}

export default NewsPage;