import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {FAILED, getRequest, LOADING, postRequest, SUCCESS} from "../../../../httpClient";
import Stack from "@mui/material/Stack";
import {CircularProgress, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";

import AddMoneyCollectedForm from "./add-money-collected-form";
import moment from "moment";
import List from "@mui/material/List";
import {Summarize} from "@mui/icons-material";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../../../utils/security-utils";
import {formatDate} from "../../../utils/date-utils";

const initialItem = {
    currencyCode: '',
    noOfCurrency: 0,
    denomination: 0,
    nameOfItem: '',
    type: '',
    titheNumber: '',
    added: false
};

const initialItems = [{
    currencyCode: '',
    noOfCurrency: 0,
    denomination: 0,
    nameOfItem: '',
    type: '',
    titheNumber: '',
    added: false
}]
const AddMoneyCollectedModal = ({ open, setOpen, event, reload }) => {

    const handleClose = () => {
        setOpen(false);
        setItems(initialItems);
        setStatus('');
    };


    const [items, setItems] = useState(initialItems);
    const [status, setStatus] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [successResponse, setSuccessResponse] = useState({});

    const auth = useAuth();

    useEffect(() => {

        if (!event.date) {
            return;
        }

        let today = new Date();
        today.setHours(0,0,0,0);

        let eventDate = new Date(event.date);
        eventDate.setHours(0,0,0,0);

        if (eventDate > today) {
            return;
        }

        let token = auth?.userData?.access_token;
        if (currencies.length <= 0) {

            getRequest(`/v2/finance/rates/by-date/${moment(new Date()).format("YYYY-MM-DD")}`, token)
                .then(resp => {
                    if (resp.ok) {
                        return resp.json();
                    }
                    throw resp;
                })
                .then(data => {
                    setCurrencies(data)
                })
                .catch(err => console.log(err))
        }
    }, [currencies, event])


    const addToItemList = item => {
        item.added = true;
        let initialItemForNewRow = initialItem;

        if (items.length === 1 && items[0].nameOfItem === '') {
            initialItemForNewRow.nameOfItem = 1;
            setItems([item, initialItemForNewRow]);
            return;
        }

        let updated = [...items];
        updated.splice(items.length -1, 1);
        updated.push(item);

        initialItemForNewRow.nameOfItem = updated.length;
        updated = [...updated, initialItemForNewRow]
        setItems(updated);
    }

    const removeFromItem = item => {
        if (items.length === 1) {
            handleClose();
            return;
        }

        if (items.length > 1) {
            let indexOfPrevious = 0;

            let current = [...items];
            current = current.filter((i, index) => {
                if (i.nameOfItem === item.nameOfItem) {
                    indexOfPrevious = index -1;
                }
                return i.nameOfItem !== item.nameOfItem
            })

            current[indexOfPrevious].added = false;
            setItems(current);
        }
    }

    const create = () => {
        if (!isAuthenticated(auth)) {
            return;
        }

        const cleanedData = items.filter((item) => item.currencyCode !== '' || item.denomination > 0 || item.type !== '' || item.noOfCurrency > 0);
        if (cleanedData.length < 1) {
            console.log('found no valid money collected item');
            return;
        }
        setStatus(LOADING);
        const revampedData = cleanedData.map(({ currencyCode, noOfCurrency, denomination, titheNumber, type}) => {
            let data =  {
                currencyCode: currencyCode,
                noOfCurrency: Number(noOfCurrency),
                type: type,
                amountCollectedPid: event.amountCollected.pid,
                denomination: Number(denomination),
            };

            if (titheNumber !== "") {
                data = {
                    ...data,
                    titheNumber: titheNumber
                }
            }

            return data;
        })

        let token = auth?.userData?.access_token;
        const body = {
            eventPid: event.pid,
            moneyCollected: revampedData
        }

        postRequest(`/v2/finance/add-money`, token, JSON.stringify(body))
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw resp
            })
            .then(data => {
                setSuccessResponse(data.saved)
                setStatus(SUCCESS);
                reload(token);
            })
            .catch(() => setStatus(FAILED));
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogTitle>{`Add money collected for ${event.name}(${moment(event.date).format("Do MMMM YYYY")})`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add money collected for this event. This operation is logged and can be traced back to you should the need arise. Ensure you double check the information you add.
                    </DialogContentText>

                    <div style={{ marginTop: '1rem'}}/>
                    {
                        status === LOADING ?
                                <Stack alignItems={"center"}>
                                    <div style={{ marginTop: '1rem'}}/>
                                    <CircularProgress color={"secondary"}/>
                                </Stack>
                            :
                            status === SUCCESS ?
                                <Stack>
                                    <Typography color={'primary'} component={"div"} variant={'h5'} sx={{ mt: '1rem', textAlign: 'center'}}>
                                        {`Money collected information has been saved for ${event.name}(${formatDate(event.date)})`}
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Summarize color={"info"}/>
                                            </ListItemIcon>
                                            <ListItemText primary={'Summary'} secondary={'Details of the information saved'}/>
                                        </ListItem>
                                        {
                                            successResponse.length ?
                                                <>
                                                {
                                                    successResponse.map((moneyCollected, i) => <ListItem key={i + new Date().getTime()}>
                                                        <ListItemText primary={'Type'} secondary={moneyCollected.type.slice(0,6)}/>
                                                        <ListItemText primary={'Currency'} secondary={<span style={{ color: 'green'}}>{moneyCollected.currencyCode}</span>}/>
                                                        <ListItemText primary={<span style={{ color: 'red'}}>Total</span>} secondary={<span>{`${new Intl.NumberFormat().format(moneyCollected.noOfCurrency * moneyCollected.denomination)}`}</span>} />
                                                    </ListItem>)
                                                }
                                                </>
                                                :null
                                        }
                                    </List>
                                </Stack>
                                :
                                <AddMoneyCollectedForm items={items} addItem={addToItemList} removeItem={removeFromItem} currencyList={currencies}/>
                    }

                </DialogContent>

                {
                    status === SUCCESS ?
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                        :
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={create}>Update</Button>
                        </DialogActions>
                }

            </Dialog>
        </div>
    );
}

export default AddMoneyCollectedModal;
