import React from "react";
import {Container, createTheme, CssBaseline, Switch, ThemeProvider} from "@mui/material";
import Stack from "@mui/material/Stack";
import AdvertDetailList from "./AdvertDetailList";
import Typography from "@mui/material/Typography";
import {Add} from "@mui/icons-material";
import Button from "@mui/material/Button";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});

const AdvertDetailContainer = ({ advertStatus, changeStatus, changeDetailStatus, deleteDetail, setOpenCreateModal }) => {

    const handleChange = () => {
        changeStatus();
    }

    return (
        <ThemeProvider theme={theme}>
            <div style={{ textAlign: 'center'}}>
                <CssBaseline />
                <Container maxWidth="lg" sx={{ mt: '3rem'}}>
                    <Stack alignItems={"center"} justifyContent={"center"} display={"flex"} spacing={2}>
                        <div>
                            <span>TURN DETAIL OFF</span><Switch checked={advertStatus.status} onClick={handleChange}/><span>TURN DETAIL ON</span>
                        </div>

                        <Button size={"large"} variant={"contained"} color={"success"} onClick={() => setOpenCreateModal(true)}>
                            Add new detail <Add/>
                        </Button>
                    </Stack>
                    <div style={{ marginTop: '2rem'}}/>
                    {
                        advertStatus.status ?
                            <>
                                <AdvertDetailList details={advertStatus.details} changeDetailStatus={changeDetailStatus} deleteDetail={deleteDetail}/>
                                <div style={{
                                    marginTop: '1rem'
                                }}/>
                            </>

                            :
                            <div>
                                <Typography variant="body1" gutterBottom>
                                    When the switch is off, it means no adds will be shown. <br/>
                                    When the switch is on, you can choose either to add a new advert or select which adds can be shown. <br/>
                                    If you choose to place an advert, you must give it a name and upload a new photo otherwise the advert will not be visible on the main webpage.
                                    <br/>When this is done, you will be shown all the available adverts.
                                    Each advert has a button which controls if it will be shown on the main page or not.
                                    <br/>
                                    If you choose to only see the available adds, you can use the switch to make them visible or not on the main page.
                                </Typography>
                            </div>
                    }

                </Container>
            </div>
        </ThemeProvider>
    )
}

export default AdvertDetailContainer;