import React, {useEffect, useState} from 'react';
import {
    Container,
    CssBaseline,
    ListItem,  ListItemIcon, ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    ThemeProvider
} from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {theme} from "../theme";
import { PowerOff, PowerSettingsNew, Refresh } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import {getRequest, LOADING} from "../httpClient";
import Loading from "../components/utils/loading";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useAuth} from "oidc-react";


function MonitoringPage() {

    const [systems, setSystems] = useState([]);
    const [status, setStatus] = useState(LOADING);

    const auth = useAuth();

    const loadSystems = () => {
        setStatus(LOADING);
        getRequest(`/v1/app-status`, auth?.userData?.access_token)
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                throw res
            })
            .then(data => {
                setSystems(data);
                setStatus('');
            })
            .catch(() => setStatus(''))
    }

    useEffect(() => {
        loadSystems();
    }, [auth.userData])

    if (status ===LOADING) {
        return (
        <div>
            <div style={{
                marginTop: '4rem'
            }}/>
            <Loading message={'loading system information...'}/>
        </div>
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <div>
                <CssBaseline />
                <Container maxWidth="lg" sx={{ mt: '3rem'}}>
                    <Box sx={{ width: '100%' }}>
                        <div>
                            {
                                systems.length > 0 ?
                                    <List>
                                        {
                                                    systems.map((system, i) =>
                                                        <div key={i}>
                                                            <ListSubheader style={{ textTransform: 'uppercase'}} color={"primary"}>
                                                                {system.details.applicationName.replace('-', ' ')}
                                                            </ListSubheader>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    {
                                                                        system.status === "DOWN" || system.status === "OUT_OF_SERVICE" ?
                                                                            <PowerOff color={"error"}/>
                                                                            :
                                                                            <PowerSettingsNew color={'success'}/>
                                                                    }

                                                                </ListItemIcon>

                                                                <ListItemText secondary={<Typography style={{
                                                                    color: system.status === "DOWN" || system.status === "OUT_OF_SERVICE" ? '#d32f2f' : '#2e7d32'
                                                                }}>{system.status}</Typography>}/>
                                                                <ListItemText primary={'Message'} secondary={system.status === "UP" ? 'system vitals are up and running' : 'system vitals are down ...'}/>
                                                                <ListItemText primary={'Last seen'} secondary={system.status === "UP" ? moment(system.lastSeen).format("YYYY-MM-DD hh:mm:ss A") : 'Not available..'}/>
                                                                <ListItemText primary={'IP'} secondary={system.status === "UP" ? system.ipAddress : 'N-A....'} />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton color={"warning"} onClick={loadSystems}>
                                                                        <Refresh/>
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </div>
                                                    )
                                                }
                                    </List>
                                    :
                                    <div>
                                        <Typography variant={"h5"} component={"div"}>
                                            No Monitoring information
                                        </Typography>
                                    </div>
                            }

                            <Stack alignItems={"center"} textAlign={"center"} sx={{
                                mt: 5
                            }}>
                                <Button style={{
                                    width: '30%'
                                }} variant={'contained'} href={'https://ebrinktech.grafana.net/login'} size={"large"} target={'_blank'}>
                                    Grafana
                                </Button>
                            </Stack>

                        </div>
                    </Box>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default MonitoringPage;
