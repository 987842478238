import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {Check, FileCopyRounded, UploadFile} from '@mui/icons-material';

import {CircularProgress, Container, createTheme, Fab, ListItem, ListItemIcon, ListItemText, ThemeProvider} from "@mui/material";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import {formDataRequest, LOADING, SUCCESS} from "../../../httpClient";
import Typography from "@mui/material/Typography";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../../utils/security-utils";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});

const UploadLandingPageModal = ({ open, setOpen }) => {

    const handleClose = () => {
        setOpen(false);
        setStatus('');
        setErrorMessage('');
        setFile(null);
        setName('');
    };

    const [file, setFile] = useState(null);
    const [name, setName]  = useState('');
    const [status, setStatus] = useState('');
    const [link, setLink] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const auth = useAuth();

    const update = () => {
        if (!file || name === '') {
            return;
        }

        if (!isAuthenticated(auth)) {
            return;
        }

        setStatus(LOADING);
        let token = auth?.userData?.access_token;

        let formData = new FormData();
        formData.append("file", file, file.name);

        formDataRequest(formData,`/v1/landingpage/detail`, token, {name: name})
            .then(resp => {
                if (resp.ok) {
                    return resp.json()
                }
                throw resp
            })
            .then(data => {
                setLink(data.link);
                setStatus(SUCCESS)
            })
            .catch(() => {
                setStatus('');
                setErrorMessage('Could not upload file at this time..please try again at a later time.')
                setTimeout(()=> setErrorMessage(''), 6000);
            })
    }

    return (
        <ThemeProvider theme={theme}>
            <div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{`Create new landing page advert`}</DialogTitle>
                    <DialogContent>
                        {
                            status !== SUCCESS ?
                                <DialogContentText>
                                    Create a new landing page advert. When download is complete, you will receive a link which can be used to play the message.
                                </DialogContentText>
                                :null
                        }

                        {
                            status === SUCCESS ?
                                null
                                :
                                <>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="title"
                                        label="Name"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        onChange={e => setName(e.target.value)}
                                        disabled={status === LOADING}
                                    />
                                </>
                        }


                        {
                            status === '' ?
                                <div>
                                    <label htmlFor={'upload-button'} style={{ cursor: 'pointer'}}>
                                        <div style={{ textAlign: 'center', marginTop: '1rem'}} >
                                            <Stack direction={'column'} spacing={2} alignItems={"center"}>
                                                <UploadFile fontSize={"large"} color={'secondary'}/>
                                            </Stack>
                                            <span>
                                    click to upload file
                                </span>
                                        </div>
                                    </label>
                                    <input id={'upload-button'} type={'file'} onChange={e => setFile(e.target.files[0])} style={{ display: "none"}}/>
                                </div>
                                :null
                        }


                        {
                            file ?
                                <Container maxWidth={"lg"}>
                                    <Box>
                                        <List>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <FileCopyRounded color={'info'}/>
                                                </ListItemIcon>
                                                <ListItemText primary={file.name} secondary={`${Math.round(file.size/1000000) <= 0 ? file.size/1000000 : Math.round(file.size/1000000)} MB`}/>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Container>

                                :
                                null
                        }

                        {
                            status === LOADING ?
                                <Stack alignItems={"center"}>
                                    <div style={{ marginTop: '1rem'}}/>
                                    <CircularProgress color={"secondary"}/>
                                    <span>
                                    uploading file...
                                </span>
                                </Stack>
                                :
                                status === SUCCESS ?
                                    <>
                                        <Stack direction={"row"} spacing={2}>
                                            <Typography color={'success'} component={"div"} variant={'h5'} sx={{ mt: '1rem', textAlign: 'center'}}>
                                                File uploaded successfully
                                            </Typography>
                                            <div style={{ marginTop: '.2rem'}}>
                                                <Fab color={"default"}>
                                                    <Check color={"success"} fontSize={'large'}/>
                                                </Fab>
                                            </div>

                                        </Stack>
                                        <DialogContentText>
                                            {`Image is available on this link: ${link}`}
                                        </DialogContentText>
                                    </>

                                    : null
                        }

                        {
                            errorMessage !== '' ?
                                <>
                                <span style={{ color: '#d32f2f'}}>
                                    {errorMessage}
                                </span>
                                </>
                                : null
                        }
                    </DialogContent>
                    {
                        status === SUCCESS ?
                            <DialogActions>
                                <Button onClick={handleClose}>Close</Button>
                            </DialogActions>
                            :
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button onClick={update}>Update</Button>
                            </DialogActions>
                    }

                </Dialog>
            </div>
        </ThemeProvider>

    );
}

export default UploadLandingPageModal;
