import React from "react";
import {CircularProgress, Container, createTheme} from "@mui/material";
import Box from "@mui/material/Box";
import {ThemeProvider} from "@mui/styles";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});

const Loading = ({ message }) => (
    <ThemeProvider theme={theme}>
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Container maxWidth="sm" sx={{ mt: '3rem'}}>
                <Box>
                    <CircularProgress/>
                </Box>
                <span>
                    {message}
                </span>
            </Container>
        </div>
    </ThemeProvider>
);

export default Loading;