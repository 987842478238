import React from "react";

const CenterDiv = ({ children }) => (
    <div style={{
        display: 'flex',
        justifyContent: 'center'
    }}>
        {children}
    </div>
)

export default CenterDiv;