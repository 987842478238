import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {ListItem,  ListItemSecondaryAction, ListItemText} from "@mui/material";

import {Delete} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {deleteRequest} from "../../../httpClient";
import List from "@mui/material/List";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../../utils/security-utils";

const DeleteNewsItemModal = ({ open, setOpen, newsItem, reload, loadNews, closeDialog }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const auth = useAuth();

    const deleteNewsItem = id => {
        if (!isAuthenticated(auth)) {
            return;
        }

        let token = auth?.userData?.access_token;

        deleteRequest(`/v1/landingpage/news?ids=${id}`, token)
            .then(resp => {
                if (resp.ok) {
                    reload();
                    loadNews();
                    handleClose();
                    closeDialog();
                    return;
                }
                throw resp;
            })
            .catch(err => console.log(err));
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogTitle>{`Delete news item ${newsItem.id}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        News item content: <br/>{newsItem.content}
                    </DialogContentText>

                    <div style={{marginTop: '1rem'}}/>
                    <List>
                        <ListItem>
                            <ListItemText primary={'Name'} secondary={newsItem.headline}/>

                            <ListItemText primary={'Author'} secondary={newsItem.author}/>

                            <ListItemSecondaryAction>
                                <IconButton color={'error'} onClick={() => deleteNewsItem(newsItem.id)}>
                                    <Delete/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>


                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>close</Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}

export default DeleteNewsItemModal;
