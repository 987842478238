import React from "react";
import List from "@mui/material/List";
import {ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Add, Info} from "@mui/icons-material";
import DetailCard from "./detail-card";
import Stack from "@mui/material/Stack";

const AdvertDetailList = ({details, changeDetailStatus, deleteDetail }) => {
    return (
        <>
            {
                details.length > 0 ?
                    <Stack alignItems={"center"} spacing={4}>
                        {
                            details.map((detail, i) =>
                                <div key={i}>
                                    <DetailCard detail={detail} changeDetailStatus={changeDetailStatus} deleteDetail={deleteDetail}/>
                                </div>
                            )
                        }
                    </Stack>
                    :
                    <List>
                    <ListItem>
                        <ListItemIcon>
                            <Info color={'warning'}/>
                        </ListItemIcon>
                        <ListItemText primary={'No Detail found'}
                                      secondary={'click the blue add button to the right to add new detail'}/>
                        <ListItemSecondaryAction>
                            <IconButton>
                                <Add color={'primary'}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    </List>
            }
        </>
    )
}

export default AdvertDetailList;