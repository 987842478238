
const APPLICATION_JSON = 'application/json';

export function httpRequest ( body, endpoint, token, method, headers = {}) {
    const baseUrl = window.API_URL;

    let updatedHeaders = {
        ...headers,
        'Content-Type': APPLICATION_JSON
    }

    if (token) {
        updatedHeaders = {
            ...updatedHeaders,
            Authorization: `Bearer ${token}`,
        }
    }

    let options = {
        method: method,
        headers: updatedHeaders
    }

    if (body) {
        options = {
            ...options,
            body: body
        }
    }
    return fetch(`${baseUrl}${endpoint}`, options);
}
export function formDataRequest (formData, endpoint, token, headers) {
    const baseUrl = window.API_URL;

    let updatedHeaders = {
        ...headers,
        Authorization: `Bearer ${token}`
    }

    let options = {
        method: 'POST',
        headers: updatedHeaders,
        body: formData
    }
    return fetch(`${baseUrl}${endpoint}`, options);
}

export function getRequest(endpoint, token, headers) {
    return httpRequest(null, endpoint, token, 'GET', headers);
}

export function postRequest(endpoint, token, body, headers) {
    return httpRequest(body, endpoint, token, 'POST', headers);
}

export function putRequest(endpoint, token, body, headers) {
    return httpRequest(body, endpoint, token, 'PUT', headers);
}

export function deleteRequest(endpoint, token, body, headers) {
    return httpRequest(body, endpoint, token, 'DELETE', headers);
}


export const LOADING = 'loading';
export const SUCCESS = 'success';
export const FAILED = 'failed';

