import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import {Add, Remove} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CurrencySelect from "./currency-select";

const moneyTypes = [
    'Tithe',
    'Offering',
    'Thanksgiving'
]
const TypeSelect = ({ setExternalType, disabled }) => {
    const [type, setType] = React.useState('');

    const handleChange = (event) => {
        setType(event.target.value);
        setExternalType(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth>
                <InputLabel id="money-collected-type">Type</InputLabel>
                <Select
                    labelId="money-collected-type"
                    id="money-collected-type-select"
                    value={type}
                    label="Type"
                    onChange={handleChange}
                    disabled={disabled}
                >
                    {
                        moneyTypes.map((type) => <MenuItem key={type} value={type}>
                            {type}
                        </MenuItem>)
                    }
                </Select>
            </FormControl>
        </Box>
    );
}
const MoneyForm = ( { addItem, index, removeItem, item, currencies }) => {

    const [currencyCode, setCurrencyCode] = useState(item.currencyCode);
    const [noOfCurrency, setNoOfCurrency] = useState(item.noOfCurrency);
    const [denomination, setDenomination] = useState(item.denomination);
    const [type, setType] = useState(item.type);
    const [titheNumber, setTitheNumber] = useState(item.titheNumber);

    const handleAddItem = () => {
        const item = {
            currencyCode,
            noOfCurrency: type === 'Tithe' ? 1 : noOfCurrency,
            denomination,
            type,
            titheNumber,
            nameOfItem: index,
            added: false
        }

        addItem(item);
    }

    const handleRemoveItem = () => {
        const item = {
            currencyCode,
            noOfCurrency,
            denomination,
            type,
            nameOfItem: index,
            titheNumber,
        }

        setCurrencyCode('');
        setNoOfCurrency('');
        setDenomination('');
        setType('');
        removeItem(item);
    }

    return (
        <Stack direction={'row'} spacing={4}>
            <TypeSelect setExternalType={setType} disabled={item.added}/>
            <CurrencySelect currencies={currencies} setCurrency={e => setCurrencyCode(e)} disabled={item.added}/>

            {
                type === 'Tithe' ?
                    <>
                        <TextField
                            margin="dense"
                            id="denomination"
                            label="Amount"
                            type="number"
                            variant="standard"
                            onChange={e => setDenomination(e.target.value)}
                            disabled={item.added}
                        />

                        <TextField
                            margin="dense"
                            id="tithe_number"
                            label="Tithe Number"
                            type="text"
                            variant="standard"
                            onChange={e => setTitheNumber(e.target.value)}
                            disabled={item.added}
                        />
                    </>

                    :
                    <>
                        <TextField
                            margin="dense"
                            id="no_of_currency"
                            label="No. Of Currency"
                            type="number"
                            variant="standard"
                            onChange={e => setNoOfCurrency(e.target.value)}
                            disabled={item.added}
                        />

                        <TextField
                            margin="dense"
                            id="denomination"
                            label="Denomination"
                            type="number"
                            variant="standard"
                            onChange={e => setDenomination(e.target.value)}
                            disabled={item.added}
                        />
                    </>
            }

            <div>
                <IconButton sx={{ top: '.5rem'}} onClick={handleAddItem} disabled={currencyCode === '' || denomination === 0 || type === ''}>
                    <Add color={ 'primary'}/>
                </IconButton>

                <IconButton sx={{ top: '.5rem'}} onClick={handleRemoveItem}>
                    <Remove color={'warning'}/>
                </IconButton>
            </div>
        </Stack>
    )
}

export default MoneyForm;