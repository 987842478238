import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchEvents, fetchIntervalSchedules} from "./eventAPI";
import {SUCCESS} from "../../httpClient";

const initialState = {
    events: [],
    status: 'idle',
    intervals: []
}

export const fetchEventsAsync = createAsyncThunk(
    'events/fetchEvents',
    async (token) => {
        return await fetchEvents(token);
    }
);

export const fetchIntervalSchedulesAsync = createAsyncThunk(
    'events/fetchIntervalSchedules',
    async (token) => {
        return await fetchIntervalSchedules(token)
    }
);

export const eventSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        loadEvents: (state,action) => {
            state.events = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEventsAsync.fulfilled, (state, action) => {
                state.status = SUCCESS;
                state.events = action.payload
            })
            .addCase(fetchIntervalSchedulesAsync.fulfilled, (state, action) => {
                state.intervals = action.payload
            })
    }
});

export default eventSlice.reducer;