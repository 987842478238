import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

import MoreIcon from '@mui/icons-material/MoreVert';
import {Avatar, createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import logo from "../../assets/logo.svg";
import Button from "@mui/material/Button";

import {
    useNavigate
} from 'react-router-dom';

import {CalendarToday, InfoSharp, Money, MonitorHeart} from "@mui/icons-material";
import {useAuth} from "oidc-react";
import {useSelector} from "react-redux";


const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(','),
    },
});

export default function Navbar() {
    let navigate = useNavigate();

    const { roles } = useSelector(state => state.user )

    const auth = useAuth();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => {
                auth.signOut();
                navigate("/loggedout")
            }}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {
                roles.indexOf('officer') > -1 ?
                    <MenuItem onClick={() => navigate("/events")}>
                        <IconButton size="small" aria-label="events" color="inherit" >
                            <CalendarToday />
                        </IconButton>
                        <p>Events</p>
                    </MenuItem>
                    :
                    null
            }

            {
                roles.indexOf('finance') > -1 ?
                    <MenuItem onClick={() => navigate("/finance")}>
                        <IconButton
                            size="small"
                            aria-label="finance"
                            color="inherit"

                        >
                            <Money />
                        </IconButton>
                        <p>Finance</p>
                    </MenuItem>
                    :
                    null
            }

            {
                roles.indexOf('media') > -1 ?
                    <MenuItem onClick={() => navigate("/news")}>
                        <IconButton
                            size="small"
                            aria-label="news"
                            color="inherit"
                        >
                            <InfoSharp />
                        </IconButton>
                        <p>News</p>
                    </MenuItem>
                    :
                    null
            }


            {
                roles.indexOf('sysops') > -1 ?
                    <div>
                        <MenuItem onClick={() => navigate("/monitoring")}>
                            <IconButton
                                size="small"
                                aria-label="monitoring"
                                color="inherit"

                            >
                                <MonitorHeart />
                            </IconButton>
                            <p>Monitoring</p>
                        </MenuItem>
                    </div>
                    :
                    null
            }


            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color={"transparent"}>
                <Toolbar>
                    <Avatar src={logo} sx={{ cursor: 'pointer' }} onClick={() => navigate("/")}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block', marginLeft: '1rem'} }}
                    >
                        Jesus Connections
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                        {
                            roles.indexOf('officer') > -1 ?
                                <Button size={"large"} variant={"text"} sx={{ fontSize: 'larger'}} onClick={() => navigate("/events")}>
                                    Events
                                </Button>
                                :
                                null
                        }

                        {
                            roles.indexOf('finance') > -1 ?
                                <Button size={"large"} variant={"text"} sx={{ fontSize: 'larger'}} onClick={() => navigate("/finance")}>
                                    Finance
                                </Button>
                                :null
                        }

                        {
                            roles.indexOf('sysops') > -1 ?
                                <>
                                    <Button size={"large"} variant={"text"} sx={{ fontSize: 'larger'}} onClick={() => navigate('/devtools')}>
                                        Dev Tools
                                    </Button>

                                    <Button size={"large"} variant={"text"} sx={{ fontSize: 'larger'}} onClick={() => navigate('/monitoring')}>
                                        Monitoring
                                    </Button>
                                </>

                                :null
                        }



                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </Box>
        </ThemeProvider>
    );
}