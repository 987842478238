import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Stack from "@mui/material/Stack";
import ChorusMenu from "./menu/chorus-menu";
import VerseMenu from "./menu/verse-menu";

const SongCard = ({song, searchClient, preview }) => {
    const chorusData = song.choruses.map((chorus) => {
        let chorusTextArray = chorus.chorus.split('\n');
        let chorusNumber = chorus.chorusNumber;

        return {
            chorusTextArray,
            chorusNumber,
            id: chorus.id
        };
    });

    chorusData.sort((a, b) => a.chorusNumber - b.chorusNumber);


    const verseData = song.verses.map((verse) => {
        let verseTextArray = verse.verseText.split('\n');
        let verseNumber = verse.verseNumber;

        return {
            verseTextArray,
            verseNumber,
            id: verse.id
        };
    })

    verseData.sort((a, b) => a.verseNumber - b.verseNumber);

    return (
        <Card sx={{display: 'flex'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <CardContent>
                    <Typography component="div" variant="h5">
                        {song.title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {
                            song.authors.map((author, i) => <span key={i}>{author.name}</span>)
                        }
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" component="div">
                        {song.yearOfRelease}
                    </Typography>
                </CardContent>

                <CardContent>
                    {
                        verseData.map((verse, i) =>
                            <div key={i + new Date()} style={{
                                marginBottom: '1rem'
                            }}>
                                <Stack direction={"row"}>
                                    <Typography variant="subtitle1" sx={{
                                        color: '#0288d1',
                                        fontSize: 'larger',
                                        textTransform: 'uppercase'
                                    }} component="div">
                                        {`Verse ${verse.verseTextArray.length > 1 ? verse.verseNumber : ''}`}
                                    </Typography>
                                    {
                                        !preview ?
                                            <div style={{
                                                marginTop: '-.2rem'
                                            }}>
                                                <VerseMenu verse={verse} song={song} searchClient={searchClient}/>
                                            </div>
                                            :
                                            null
                                    }

                                </Stack>
                                {
                                    verse.verseTextArray.map((c, i) => <Typography variant="subtitle2"
                                                                                   color="text.secondary"
                                                                                   component="div"
                                                                                   key={i + new Date()}>{c}</Typography>)
                                }
                            </div>
                        )
                    }
                </CardContent>

                <CardContent>
                    {
                        chorusData.map((chrs, i) => <div key={i + new Date()} style={{
                            marginBottom: '1rem'
                        }}>
                            <Stack direction={"row"}>
                                <Typography variant="subtitle1" sx={{
                                    color: '#d32f2f',
                                    fontSize: 'larger',
                                    textTransform: 'uppercase'
                                }} component="div">
                                    {`Chorus ${chrs.chorusTextArray.length > 1 ? chrs.chorusNumber : ''}`}
                                </Typography>
                                {
                                    !preview ?
                                        <div style={{
                                            marginTop: '-.2rem'
                                        }}>
                                            <ChorusMenu chorus={chrs} song={song} searchClient={searchClient}/>
                                        </div>
                                        :
                                        null
                                }

                            </Stack>
                            {
                                chrs.chorusTextArray.map((c, i) => <Typography variant="subtitle2"
                                                                               color="text.secondary" component="div"
                                                                               key={i + new Date()}>{c}</Typography>)
                            }
                        </div>)
                    }

                </CardContent>
            </Box>

        </Card>
    );
}

export default SongCard;