import * as React from 'react';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import {visuallyHidden} from '@mui/utils';
import {Container, createTheme, CssBaseline} from "@mui/material";
import {
    ShowChart
} from "@mui/icons-material";
import {ThemeProvider} from "@emotion/react";
import FinanceViewDialogContainer from "./finance-view-dialog-container";
import GenerateReportModal from "./modals/money-collected/generate-report-modal";
import moment from "moment";

const rowPerPageOptionsConfig = [10,20,40];

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});

function descendingComparator(a, b, orderBy) {

    const aDate = new Date(a["date"]);
    const bDate = new Date(b["date"]);


    if (bDate.getTime() < aDate.getTime()) {
        return 1
    }

    if (bDate.getTime() > aDate.getTime()) {
        return -1
    }

    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Select',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'start',
        numeric: true,
        disablePadding: false,
        label: 'Start Time',
    },
    {
        id: 'end',
        numeric: true,
        disablePadding: false,
        label: 'End Time',
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total (SEK)',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        console.log(property, event)
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all events',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { selected, data, setCreateModal } = props;
    let numSelected = selected.length;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Events
                </Typography>
            )}

            <Tooltip title="Generate report">
                <IconButton color={'error'} onClick={() => setCreateModal(true)}>
                    <ShowChart />
                </IconButton>
            </Tooltip>

            {numSelected > 0 ? (
                <FinanceViewDialogContainer selectedIds={selected} data={data}/>
            ) : null }
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    selected: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired
};

const FinanceTable = ({ data, loadEvents, events, setReloadData, currentPage }) => {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('date');
    const [selected, setSelected] = React.useState([]);
    const [dense, _] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(data.numberPerPage);
    const [revampedData, setRevampedData] = React.useState({});
    const [subset, setSubset] = React.useState({});
    const [createModal, setCreateModal] = React.useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = events.map((n) => n.pid);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        loadEvents(newPage, rowsPerPage, data.lastItemId);
    };

    const handleChangeRowsPerPage = (event) => {
        let size = parseInt(event.target.value, 10);
        setRowsPerPage(size);
        setReloadData(size);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        currentPage > 0 ? Math.max(0, (1 + currentPage) * rowsPerPage - events.length) : 0;

    useEffect(() => {
        let head = {}
        events.forEach((event) => {
            head = {
                ...head,
                [event.pid] : event
            }
        });

        setRevampedData(head);
    }, [events, data]);


    useEffect(() => {
        let s = {};
        const numSelected = selected.length;

        if (numSelected > 0) {
            selected.forEach(id => {
                s = {
                    ...s,
                    [id]: revampedData[id]
                }
            });
        }

        setSubset(s);
    }, [selected, revampedData]);

    return (
        <ThemeProvider theme={theme}>

        <div>
            <CssBaseline />
            <Container maxWidth="lg" sx={{ mt: '3rem'}}>
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} selected={selected} data={subset} setCreateModal={setCreateModal} createModal={createModal}/>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={events.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {events.slice().sort(getComparator(order, "date"))
                                .slice(currentPage * data.noOfItemsInBatch, currentPage * data.noOfItemsInBatch + data.noOfItemsInBatch)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.pid);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.pid)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.pid}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                                sx={{ fontSize: 'large' }}
                                            >
                                                {""}
                                            </TableCell>
                                            <TableCell sx={{ fontSize: 'large' }} align="right">{row.name}</TableCell>
                                            <TableCell sx={{ fontSize: 'large' }} align="right">{moment(row.date).format("Do MMMM YYYY")}</TableCell>
                                            <TableCell sx={{ fontSize: 'large' }} align="right">{row.startTime}</TableCell>
                                            <TableCell sx={{ fontSize: 'large' }} align="right">{row.endTime}</TableCell>
                                            <TableCell sx={{ fontSize: 'large' }} align="right">{row.totalInSek}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={rowPerPageOptionsConfig}
                    component="div"
                    count={data.total}
                    rowsPerPage={data.numberPerPage}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </Box>
            </Container>
        </div>
            <GenerateReportModal open={createModal} setOpen={setCreateModal}/>
        </ThemeProvider>
    );
}

export default FinanceTable;
