import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {Check, FileCopyRounded, UploadFile} from '@mui/icons-material';

import {CircularProgress, Container, Fab, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import {formDataRequest, LOADING, SUCCESS} from "../../../httpClient";
import Typography from "@mui/material/Typography";
import {isAuthenticated} from "../../utils/security-utils";
import {useAuth} from "oidc-react";

const UploadMp3Modal = ({ open, setOpen, event }) => {

    const handleClose = () => {
        setOpen(false);
        setStatus('');
        setErrorMessage('');
        setFile(null);
        setTitle('');
        setSpeaker('');
    };

    const [title, setTitle] = useState('');
    const [speaker, setSpeaker] = useState('');
    const [file, setFile] = useState(null);
    const [status, setStatus] = useState('');
    const [link, setLink] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const auth = useAuth();

    const update = () => {
        if (!file || speaker === '' || title === '') {
            return;
        }

        if (!isAuthenticated(auth)) {
            return;
        }

        setStatus(LOADING);
        let token = auth?.userData?.access_token;
        let headers = {
            speaker: speaker,
            title: title
        }

        let formData = new FormData();
        formData.append("file", file, file.name);

        formDataRequest(formData,`/v1/mp3/${event.id}`, token, headers)
            .then(resp => {
                if (resp.ok) {
                    return resp.json()
                }
                throw resp
            })
            .then(data => {
                setLink(data.link);
                setStatus(SUCCESS)
            })
            .catch(() => {
                setStatus('');
                setErrorMessage('Could not upload file at this time..please try again at a later time.')
                setTimeout(()=> setErrorMessage(''), 6000);
            })
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Upload mp3 message for ${event.name}(${event.date})`}</DialogTitle>
                <DialogContent>
                    {
                        status !== SUCCESS ?
                            <DialogContentText>
                                Upload the mp3 message for this event. When download is complete, you will receive a link which can be used to play the message.
                            </DialogContentText>
                            :null
                    }

                    {
                        status === SUCCESS ?
                            null
                            :
                            <>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="title"
                                    label="Title"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={e => setTitle(e.target.value)}
                                    disabled={status === LOADING}
                                />

                                <TextField
                                    margin="dense"
                                    id="speaker"
                                    label="Speaker"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    onChange={e => setSpeaker(e.target.value)}
                                    disabled={status === LOADING}
                                />
                            </>
                    }


                    {
                        status === '' ?
                            <div>
                                <label htmlFor={'upload-button'} style={{ cursor: 'pointer'}}>
                                    <div style={{ textAlign: 'center', marginTop: '1rem'}} >
                                        <Stack direction={'column'} spacing={2} alignItems={"center"}>
                                            <UploadFile fontSize={"large"} color={'secondary'}/>
                                        </Stack>
                                        <span>
                                    click to upload file
                                </span>
                                    </div>
                                </label>
                                <input id={'upload-button'} type={'file'} onChange={e => setFile(e.target.files[0])} style={{ display: "none"}}/>
                            </div>
                            :null
                    }


                    {
                        file ?
                            <Container maxWidth={"lg"}>
                                <Box>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <FileCopyRounded color={'info'}/>
                                            </ListItemIcon>
                                            <ListItemText primary={file.name} secondary={`${Math.round(file.size/1000000)} MB`}/>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Container>

                            :
                            null
                    }

                    {
                        status === LOADING ?
                            <Stack alignItems={"center"}>
                                <div style={{ marginTop: '1rem'}}/>
                                <CircularProgress color={"secondary"}/>
                                <span>
                                    uploading file...
                                </span>
                            </Stack>
                            :
                            status === SUCCESS ?
                                <>
                                    <Stack direction={"row"} spacing={2}>
                                        <Typography color={'success'} component={"div"} variant={'h5'} sx={{ mt: '1rem', textAlign: 'center'}}>
                                            File uploaded successfully
                                        </Typography>
                                        <div style={{ marginTop: '.2rem'}}>
                                            <Fab color={"default"}>
                                                <Check color={"success"} fontSize={'large'}/>
                                            </Fab>
                                        </div>

                                    </Stack>
                                    <DialogContentText>
                                        {`You can stream this message online with ${link}`}
                                    </DialogContentText>
                                </>

                                : null
                    }

                    {
                        errorMessage !== '' ?
                            <>
                                <span style={{ color: '#d32f2f'}}>
                                    {errorMessage}
                                </span>
                            </>
                            : null
                    }
                </DialogContent>
                {
                    status === SUCCESS ?
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                        :
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={update}>Update</Button>
                        </DialogActions>
                }

            </Dialog>
        </div>
    );
}

export default UploadMp3Modal;
