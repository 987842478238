import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function RecurringSwitch ({ setRecurring, recurring }) {
    return (
        <FormGroup>
            <FormControlLabel control={<Switch defaultChecked={recurring} onChange={e=> setRecurring(e.target.checked)}/>} label="recurring" />
        </FormGroup>
    );
}