import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {deleteRequest} from "../../../../httpClient";
import {ListItem,  ListItemSecondaryAction, ListItemText} from "@mui/material";

import List from "@mui/material/List";
import {Delete} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useEffect} from "react";
import {isAuthenticated} from "../../../utils/security-utils";
import {useAuth} from "oidc-react";

const DeleteMoneyCollectedModal = ({open, setOpen, event, reload}) => {

    const handleClose = () => {
        setOpen(false);
    };


    const auth = useAuth();

    const deleteMoney = id => {
        if (!isAuthenticated(auth)) {
            return;
        }

        let token = auth?.userData?.access_token;

        deleteRequest(`/v1/finance?ids=${id}`, token)
            .then(resp => {
                if (resp.ok) {
                    reload(token);
                    return;
                }
                throw resp
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        if (event?.amountCollected?.moneyCollected.length === 0) {
            handleClose();
        }
    }, [event])
    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogTitle>{`Delete money collected for ${event.name}(${event.date})`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Delete money collected for this event. This operation is logged and can be traced back to you
                        should the need arise. Ensure you double check the information you add.
                    </DialogContentText>

                    <div style={{marginTop: '1rem'}}/>
                    {
                        event && event.amountCollected && event.amountCollected.moneyCollected.length > 0?
                            <List>
                                {
                                    event?.amountCollected?.moneyCollected.map((money, i) =>
                                        <ListItem key={i + new Date().getTime()}>
                                            <ListItemText secondary={i+1}/>
                                            <ListItemText primary={'Type'} secondary={money?.type}/>
                                            <ListItemText primary={'Total For SEK'}
                                                          secondary={new Intl.NumberFormat('se-SE', { style: 'currency', currency: 'SEK' })
                                                .format(money?.currencyCode.toLowerCase() === 'sek' ? money.totalForOriginalCurrency : money.totalForSEK)}/>
                                            <ListItemText primary={'Currency'} secondary={money?.currencyCode}/>

                                            <ListItemSecondaryAction>
                                                <IconButton color={'error'} onClick={() => deleteMoney(money.id)}>
                                                    <Delete/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                            </List>
                            :null
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>close</Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}

export default DeleteMoneyCollectedModal;
