import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    roles: []
}


export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload
        },
        clear: (state) => {
            state = initialState;
        }
    }
});

export const { setRoles, clear } = userSlice.actions;




export default userSlice.reducer;