import * as React from 'react';

import {useEffect, useState} from "react";
import {getRequest} from "../../../httpClient";
import {Add} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {theme as theme2} from "../../../theme";
import {ThemeProvider} from "@emotion/react";
import Autocomplete from '@mui/material/Autocomplete'
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../../utils/security-utils";

const AuthorSelect = ({ addToAuthorList }) => {
    const auth = useAuth();
    const [options, setOptions] = useState([]);

    const [addDialogState, setAddDialogState] = useState(false);
    const [dialogName, setDialogName] = useState('');

    const handleClose = () => {
        setAddDialogState(false);
        setDialogName('');
    }

    const handleSubmit = () => {
        if (dialogName === '') {
            return;
        }

        const name = dialogName.split(' ').map(n => n.charAt(0).toUpperCase()+n.slice(1)).toString().replaceAll(',', ' ');
        if (options.find(option => option.name === name)) {
            handleClose();
            return;
        }

        const newAuthor = {
            firstLetter: name[0],
            name: name
        };

        setOptions([...options, newAuthor]);
        handleClose();
    }

    const handleChange = (_, newAuthors) => {
        addToAuthorList(newAuthors);
    };

    const loadAuthors = () => {
        if (!isAuthenticated(auth)) {
            return;
        }

        getRequest(`/v1/song/author`, auth?.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                throw resp;
            })
            .then(data => {
                let revampedData = data.map(a => a.name);
                let o = revampedData.map((option) => {
                    const firstLetter = option[0].toUpperCase();
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                        name: option,
                    };
                });

                setOptions(o);
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        loadAuthors();
    },[auth.userData]);

    return (
        <ThemeProvider theme={theme2}>
            <div>
                        <Autocomplete
                            id="authors"
                            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            multiple
                            onChange={handleChange}
                            sx={{ width: 300 }}
                            renderInput={(params) => {
                                return <TextField {...params} label="Authors"
                                                  placeholder="Authors"/>
                            }}
                        />


                <div style={{
                    marginTop: '.2rem'
                }}>
                        <IconButton onClick={() => setAddDialogState(true)}>
                            <Add color={'success'}/>
                        </IconButton>
                </div>
            </div>
            <Dialog open={addDialogState} onClose={handleClose}>
                    <DialogTitle>Add a new author</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Can't find the author? Please add the author to our list!
                        </DialogContentText>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogName}
                            onChange={e => setDialogName(e.target.value)}
                            label="name"
                            type="text"
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => handleSubmit(dialogName)}>Add</Button>
                    </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default AuthorSelect;