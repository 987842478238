import React from "react";
import {
    Container,
    createTheme,
    CssBaseline,
    ListItem, ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ThemeProvider
} from "@mui/material";
import List from "@mui/material/List";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import {Delete, Map} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {FAILED, SUCCESS} from "../../httpClient";
import ErrorMessage from "../utils/error-message";
import SuccessMessage from "../utils/success-message";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans Condensed',
            'sans-serif'
        ].join(',')
    },
});

const TrafficDetailContainer = ({ latestMap, deleteLatestMap, deleteStatus, loadMap }) => {

    return (
        <ThemeProvider theme={theme}>
            <div style={{ textAlign: 'center'}}>
                <CssBaseline />
                <Container maxWidth="lg" sx={{ mt: '3rem'}}>
                    <>
                        {
                            latestMap ?
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Map color={"primary"}/>
                                        </ListItemIcon>
                                        <ListItemText primary={'ID'} secondary={latestMap.id}/>
                                        <ListItemText primary={'Date Updated'} secondary={latestMap.dateUpdated}/>
                                        <ListItemText primary={'Time Updated'} secondary={moment(latestMap.dateUpdated+"T"+latestMap.timeUpdated).calendar()}/>
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={deleteLatestMap} disabled={deleteStatus !== ''}>
                                                <Delete  color={"error"}/>
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>:
                                null
                        }
                    </>
                    <div style={{ marginTop: '2rem'}}/>
                    <Button variant={"contained"} color={"success"} size={"large"} onClick={loadMap}>
                        load latest map <Map/>
                    </Button>

                    {
                        deleteStatus === FAILED ?
                            <ErrorMessage component={'div'} variant={'h6'} message={'Could not delete map..please try again later'}/>
                            :
                            deleteStatus === SUCCESS ?
                                <SuccessMessage component={'div'} variant={'h6'} message={'Map deleted asynchronously..might take a few minutes before information is updated.'}/>
                                :null
                    }
                </Container>
            </div>
        </ThemeProvider>
    )
}

export default TrafficDetailContainer;