import React, {useEffect, useState} from 'react';
import {
    Container,
    CssBaseline,
    ListItem, ListItemIcon,
    ListItemText,
    ListSubheader,
    ThemeProvider
} from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import {theme} from "../theme";
import {Computer, DataObject, DataUsage, Fingerprint, Link,  Route} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {getRequest} from "../httpClient";
import {useAuth} from "oidc-react";

function DevtoolsPage() {
    const auth = useAuth();
    
    const [serviceConfigurations, setServiceConfigurations] = useState({});
    const [routes, setRoutes] = useState({});

    const loadServiceConfigurations = () => {
        getRequest(`/v1/service-configurations`, auth.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json()
                }
                throw resp;
            })
            .then(data => {
                setServiceConfigurations(data);
            })
            .catch(err => console.log(err))
    }

    const loadServiceRoutes = () => {
        getRequest(`/v1/routes`, auth.userData?.access_token)
            .then(resp => {
                if (resp.ok) {
                    return resp.json()
                }
                throw resp;
            })
            .then(data => {
                setRoutes(data);
            })
            .catch(err => console.log(err))
    }

    useEffect(()=> {
        if (auth && auth.userData) {
            loadServiceConfigurations();
            loadServiceRoutes();
        }
    }, [auth.userData?.access_token])

    return (
        <ThemeProvider theme={theme}>
            <div>
                <CssBaseline />
                <Container maxWidth="lg" sx={{ mt: '3rem'}}>
                    <Box sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        {
                            auth && auth.userData ?
                                <>
                                    <div>
                                        <List>
                                            <ListSubheader>
                                                <Typography variant={'h6'} color={'primary'} component={'div'}>
                                                    Auth <span><Fingerprint color={"error"} fontSize={"small"}/></span>
                                                </Typography>
                                            </ListSubheader>
                                            <ListItem>
                                                <ListItemText primary={'token'} secondary={auth.userData.access_token} sx={{
                                                    wordBreak: 'break-all'
                                                }}/>
                                            </ListItem>
                                            <Divider/>
                                            <div style={{
                                                marginTop: '2rem'
                                            }}/>
                                            <ListSubheader>
                                                <Typography variant={'h6'} color={'primary'} component={'div'}>
                                                    API Urls <span><Link color={"error"} fontSize={"small"}/></span>
                                                </Typography>
                                            </ListSubheader>
                                            <ListItem>
                                                <ListItemText primary={'admin'} secondary={window.API_URL} sx={{
                                                    wordBreak: 'break-all'
                                                }}/>
                                            </ListItem>

                                            <Divider/>
                                            <div style={{
                                                marginTop: '2rem'
                                            }}/>
                                            <ListSubheader>
                                                <Typography variant={'h6'} color={'primary'} component={'div'} style={{
                                                    textTransform: 'capitalize'
                                                }}>
                                                    Services <span><DataUsage color={"error"} fontSize={"small"}/></span>
                                                </Typography>
                                            </ListSubheader>
                                            {
                                                Object.keys(serviceConfigurations).map((key, i) =>
                                                    <div key={key+i}>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Computer/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={'Port'} secondary={serviceConfigurations[key].rSocketPort}/>
                                                            <ListItemText primary={'Host name'} secondary={serviceConfigurations[key].hostname}/>
                                                            <ListItemText primary={'Service'} secondary={key.slice(0, key.length - 7)}/>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }

                                            <Divider/>
                                            <div style={{
                                                marginTop: '2rem'
                                            }}/>
                                            <ListSubheader>
                                                <Typography variant={'h6'} color={'primary'} component={'div'} style={{
                                                    textTransform: 'capitalize'
                                                }}>
                                                    Routes <span><DataObject color={"error"} fontSize={"small"}/></span>
                                                </Typography>
                                            </ListSubheader>

                                            {
                                                Object.keys(routes).map((key, i) =>
                                                    <div key={i + new Date().getTime()}>
                                                        <ListItem>
                                                            <ListItemIcon>
                                                                <Route color={"success"}/>
                                                            </ListItemIcon>
                                                            <ListItemText primary={'Route'} secondary={key}/>
                                                        </ListItem>
                                                        {
                                                            Object.keys(routes[key]).map((route, i) =>
                                                                <div key={i+new Date().getTime()}>
                                                                    <ListItem>
                                                                        <ListItemText primary={'Name'} secondary={route}/>
                                                                        <ListItemText primary={'Path'} secondary={routes[key][route]}/>
                                                                    </ListItem>
                                                                    <Divider/>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }

                                        </List>
                                    </div>
                                </>
                                :
                                null
                        }

                    </Box>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default DevtoolsPage;
