import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';


import EventDateSelector from "./event-date-selector";

export default function EventTimeSelector ({ setStartTime, setEndTime, setDate, date, startTime, endTime }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                <EventDateSelector setSelectedDate={setDate} date={date}/>

                <TimePicker
                    renderInput={(params) => <TextField {...params} />}
                    value={startTime}
                    label="Start time"
                    onChange={(newValue) => {
                        setStartTime(newValue)
                    }}
                />

                <TimePicker
                    renderInput={(params) => <TextField {...params} />}
                    value={endTime}
                    label="End time"
                    onChange={(newValue) => {
                        setEndTime(newValue)
                    }}
                    minTime={startTime}
                />
            </Stack>
        </LocalizationProvider>
    );
}