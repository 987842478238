import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import Stack from '@mui/material/Stack';

export default function EventDateSelector ({ setSelectedDate, date }) {

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <DatePicker
                    label="Date"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    value={date}
                    onChange={(newValue) => {
                        setSelectedDate(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    );
}