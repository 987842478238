import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useState} from "react";
import {putRequest} from "../../../httpClient";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../../utils/security-utils";

const EditChorusModal = ({ open, setOpen, song, chorus, searchClient }) => {

    const handleClose = () => {
        setOpen(false);
    };

    const [chorusText, setChorusText] = useState('');

    const auth = useAuth();

    const update = () => {
        if (chorusText === '') {
            return;
        }

        if (!isAuthenticated(auth)) {
            return;
        }

        let filteredChoruses = [...song.choruses.filter(s => s.id !== chorus.id)];
        let currentChorusToBeUpdated = {
            ...chorus,
            chorus: chorusText
        }

        let updatedChoruses = [...filteredChoruses, currentChorusToBeUpdated];
        console.log(updatedChoruses)


        const songRequest = {
            authors: song.authors,
            choruses: updatedChoruses,
            verses: song.verses,
            yearOfRelease: song.yearOfRelease,
            title: song.title,
            id: song.id,
            objectID: song.objectID,
        }

        putRequest(`/v1/song/${song.id}`, auth?.userData?.access_token, JSON.stringify(songRequest))
            .then(resp => {
                if (resp.ok) {
                    searchClient.clearCache();
                    handleClose();
                    return;
                }
                throw resp;
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{`Update verse ${chorus.chorusNumber} for ${song.title}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update a verse text and save it. This can be used when a wrong text has been identified.
                    </DialogContentText>

                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="current"
                        fullWidth
                        margin={"dense"}
                        variant={"standard"}
                        multiline
                        defaultValue={chorus.chorus}
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="chorus-text"
                        label="chorus"
                        type="text"
                        fullWidth
                        variant="standard"
                        multiline
                        onChange={e => setChorusText(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={update}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditChorusModal;
