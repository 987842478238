import * as React from 'react';
import Stack from '@mui/material/Stack';

import MoneyForm from "./money-form";


export default function AddMoneyCollectedForm({ items, addItem, removeItem, currencyList }) {
    return (
        <div>
            <Stack direction="column" spacing={2}>
                {
                    items.map((item, i) => <MoneyForm removeItem={removeItem} addItem={addItem} index={i} key={i} item={item} currencies={currencyList}/> )
                }

            </Stack>
        </div>
    );
}