import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {Delete, Edit} from '@mui/icons-material'


const actions = [
    { icon: <Delete color={"error"}/>, name: 'Delete news' },
    { icon: <Edit color={"primary"}/>, name: 'Edit news' }
];

const NewsEditMenu = ( { newsItem, setSelectedNewsItem,handleShowDeleteModal, handleShowEditModal }) => {

    const handleOnHover = () => {
        setSelectedNewsItem(newsItem);
    }

    const handleOnLeave = () => {
        setSelectedNewsItem({})
    }
    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
            <SpeedDial
                onMouseEnter={handleOnHover}
                onMouseLeave={handleOnLeave}
                ariaLabel="event edit menu"
                sx={{ position: 'absolute', bottom: 20, right: 16 }}
                icon={<SpeedDialIcon/>}
            >
                        <SpeedDialAction key={actions[0].name} icon={actions[0].icon} tooltipTitle={actions[0].name} onClick={handleShowDeleteModal}/>
                        <SpeedDialAction key={actions[1].name} icon={actions[1].icon} tooltipTitle={actions[1].name} onClick={handleShowEditModal}/>
            </SpeedDial>
        </Box>
    );
}

export default NewsEditMenu;