import React, {Fragment} from "react";
import Stack from "@mui/material/Stack";
import RecurringSwitch from "./recurring-switch";
import TextField from "@mui/material/TextField";
import IntervalSchedules from "./interval-schedules";

const IntervalSettings = ({ schedules, setRecurring, setIntervalSchedule, setIntervalRate, intervalScheduleError, intervalRateError, recurring }) => {
    return (
        <Stack spacing={3} direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <RecurringSwitch setRecurring={setRecurring} recurring={recurring}/>

            {
                recurring ?
                    <Fragment>
                        <IntervalSchedules schedules={schedules} selectIntervalSchedule={setIntervalSchedule} error={intervalScheduleError}/>

                        <TextField
                            margin="dense"
                            id="name"
                            label="Rate"
                            type="number"
                            fullWidth={false}
                            variant="outlined"
                            onChange={e => setIntervalRate(Number(e.target.value))}
                            error={intervalRateError}
                        />
                    </Fragment>
                    :
                    null
            }
        </Stack>
    )
};
export default IntervalSettings;