import React, {Fragment, useState} from "react";
import IconButton from "@mui/material/IconButton";
import {Add, Delete, Edit, ZoomIn} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import {
    Dialog, DialogContent,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import AuthorSelect from "./author/author-select";
import CenterDiv from "../utils/center-div";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";
import List from "@mui/material/List";
import AddVerseModal from "./modal/create-song/add-verse-modal";
import EditModal from "./modal/create-song/edit-modal";
import AddChorusModal from "./modal/create-song/add-chorus-modal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import SongCard from "./song-card";
import DialogActions from "@mui/material/DialogActions";
import {FAILED, LOADING, postRequest, SUCCESS} from "../../httpClient";
import Loading from "../utils/loading";
import {useAuth} from "oidc-react";
import {isAuthenticated} from "../utils/security-utils";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VERSE = 'verse';
const CHORUS = 'chorus';

const SongDialogContainer = () => {
    const [open, setOpen] = React.useState(false);
    const [songTitle, setSongTitle] = useState('');
    const [yearOfRelease, setYearOfRelease] = useState(new Date());
    const [verses, setVerses] = useState([]);
    const [choruses, setChoruses] = useState([]);
    const [authors, setAuthors] = useState([]);

    const [addVerseDialogState, setAddVerseDialogState] = useState(false);
    const [addChorusDialogState, setAddChorusDialogState] = useState(false);

    const [verseEditModalState, setVerseEditModalState] = useState(false);
    const [selectedVerseNumber, setSelectedVerseNumber] = useState(-1);

    const [chorusEditModalState, setChorusEditModalState] = useState(false);
    const [selectedChorusNumber, setSelectedChorusNumber] = useState(-1);

    const [previewSong, setPreviewSong] = useState(false);
    const [status, setStatus] = useState('');

    const auth = useAuth();

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setSongTitle('');
        setYearOfRelease(new Date());
        setVerses([]);
        setChoruses([]);

        setAuthors([]);
        setStatus('');
    };

    const buildSongFromData = () =>{
        return {
            yearOfRelease: moment(yearOfRelease).format('YYYY'),
            releaseYear: moment(yearOfRelease).format('YYYY'),
            choruses: choruses.map((chorus, i) => {
                return {
                    chorus,
                    chorusNumber: i + 1,
                    id: 'bogus'
                }
            }),
            verses: verses.map((verse, i) => {
                return {
                    verseText: verse,
                    verseNumber: i + 1,
                    id: 'bogus'
                }
            }),
            authors,
            title: songTitle
        }
    }

    const addVerse = verse => {
        const updatedVerses = [...verses, verse];
        setVerses(updatedVerses);
    }

    const addChorus = chorus => {
        const updatedChoruses = [...choruses, chorus];
        setChoruses(updatedChoruses);
    }

    const handleSetYearOfRelease = date => {
        setYearOfRelease(date);
    }

    const editVerse = (text, number) => {
        let updatedVerses = [...verses];
        updatedVerses[number] = text;

        setVerses(updatedVerses);
    }

    const editChorus = (text, number) => {
        let updatedChoruses = [...choruses];
        updatedChoruses[number] = text;

        setChoruses(updatedChoruses);
    }

    const deleteVerse = number => {
        let updatedVerses = [...verses].filter((_, i) => i !== number);
        setVerses(updatedVerses);
    }

    const deleteChorus = number => {
        let updatedChoruses = [...choruses].filter((_, i) => i !== number);
        setChoruses(updatedChoruses);
    }

    const createSong = () => {
        const song = JSON.stringify(buildSongFromData());

        if (isAuthenticated(auth)) {
            setStatus(LOADING);
            setPreviewSong(false);

            postRequest(`/v1/song`, auth?.userData?.access_token, song)
                .then(resp => {
                    if (resp.ok) {
                        return resp.json();
                    }
                    throw resp
                })
                .then(data => {
                    console.log(data);
                    setStatus(SUCCESS);
                })
                .catch(err => {
                    console.log(err);
                    setStatus(FAILED);
                })
        }
    }

    return (
        <div>
            <Tooltip title="Add new song">
                <Button onClick={handleClickOpen} variant={"contained"} color={"success"}>
                    Add new song <Add/>
                </Button>
            </Tooltip>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {`Add a new song`}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            close
                        </Button>
                    </Toolbar>
                </AppBar>

                <Fragment>
                    {
                        status === LOADING ?
                            <Loading message={'one moment please...'}/>
                            :
                            status === SUCCESS ?
                                <div style={{
                                    marginTop: '4rem'
                                }}>
                                    <CenterDiv>
                                        <Typography
                                            variant="h4"
                                            noWrap
                                            component="div"
                                            color={"#2e7d32"}
                                        >
                                            {`${songTitle} has been added`}
                                        </Typography>
                                    </CenterDiv>

                                    <div style={{
                                        marginTop: '1rem'
                                    }}>
                                        <CenterDiv>
                                            <Button size={"large"} onClick={handleClose} variant={"contained"} color={"success"}>
                                                close
                                            </Button>
                                        </CenterDiv>
                                    </div>
                                </div>
                                :
                                status === FAILED ?
                                    <div style={{
                                        margin: '4rem'
                                    }}>
                                        <CenterDiv>
                                            <Typography
                                                variant="h4"
                                                noWrap
                                                component="div"
                                                color={"#d32f2f"}
                                            >
                                                {`${songTitle} could not be added`}
                                            </Typography>
                                        </CenterDiv>

                                        <div style={{
                                            marginTop: '1rem'
                                        }}>
                                            <CenterDiv>
                                                <Stack direction={"row"} spacing={2}>
                                                    <Button size={"large"} onClick={handleClose} variant={"contained"} color={"info"}>
                                                        close
                                                    </Button>

                                                    <Button size={"large"} onClick={() => setStatus('')} variant={"contained"} color={"warning"}>
                                                        try again
                                                    </Button>
                                                </Stack>

                                            </CenterDiv>
                                        </div>
                                    </div>
                                    :
                            <div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Stack direction={"row"} justifyContent={"center"} sx={{
                                        width: '42vw'
                                    }}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="link"
                                            label="Title"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            onChange={e => setSongTitle(e.target.value)}
                                        />
                                    </Stack>
                                </div>

                                <CenterDiv>
                                    <Stack sx={{
                                        marginTop: '1rem'
                                    }} direction={"row"} spacing={1}>
                                        <AuthorSelect addToAuthorList={setAuthors}/>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Release year"
                                                disableFuture
                                                openTo="year"
                                                views={['year']}
                                                value={yearOfRelease}
                                                onChange={(newValue) => {
                                                    handleSetYearOfRelease(newValue)
                                                }}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                </CenterDiv>

                                <CenterDiv>
                                    <Stack sx={{
                                        marginTop: '1rem'
                                    }} direction={"row"} spacing={1}>
                                        <Button size={"large"}
                                                color={"primary"}
                                                onClick={() => setAddVerseDialogState(true)}
                                                disabled={songTitle === ''}>
                                            Add verse <Add/>
                                        </Button>

                                        <Button size={"large"}
                                                color={"secondary"}
                                                disabled={songTitle === ''}
                                                onClick={() => setAddChorusDialogState(true)}>
                                            Add chorus <Add/>
                                        </Button>

                                        <Button size={"large"} color={"warning"} disabled={songTitle === ''} onClick={() => setPreviewSong(true)}>
                                            Preview song <ZoomIn/>
                                        </Button>
                                    </Stack>
                                </CenterDiv>

                                <div style={{
                                    margin: '2rem'
                                }}>
                                    {
                                        verses.length > 0 ?
                                            <List>
                                                <ListSubheader>Verses</ListSubheader>
                                                {
                                                    verses.map((verse, i) =>
                                                        <ListItem key={i + new Date()}>
                                                            <ListItemText primary={'Verse No. '} secondary={i + 1}/>
                                                            <ListItemText primary={'Verse text'}
                                                                          secondary={verse.length > 100 ? verse.substring(0, 100) : verse}/>
                                                            <ListItemSecondaryAction>
                                                                <IconButton color={"primary"} onClick={() => {
                                                                    setSelectedVerseNumber(i + 1);
                                                                    setVerseEditModalState(true);
                                                                }}>
                                                                    <Edit/>
                                                                </IconButton>

                                                                <IconButton color={"error"} onClick={() => deleteVerse(i)}>
                                                                    <Delete/>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                                }
                                            </List>
                                            :
                                            null
                                    }

                                    {
                                        choruses.length > 0 ?
                                            <List>
                                                <ListSubheader>Chorus</ListSubheader>
                                                {
                                                    choruses.map((chorus, i) =>
                                                        <ListItem key={i + new Date()}>
                                                            <ListItemText primary={'Chorus No. '} secondary={i + 1}/>
                                                            <ListItemText primary={'Chorus text'}
                                                                          secondary={chorus.length > 100 ? chorus.substring(0, 100) : chorus}/>
                                                            <ListItemSecondaryAction>
                                                                <IconButton color={"primary"} onClick={() => {
                                                                    setSelectedChorusNumber(i + 1);
                                                                    setChorusEditModalState(true);
                                                                }}>
                                                                    <Edit/>
                                                                </IconButton>

                                                                <IconButton color={"error"} onClick={() => deleteChorus(i)}>
                                                                    <Delete/>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>)
                                                }
                                            </List>
                                            :
                                            null
                                    }
                                </div>

                                <CenterDiv>
                                        <Button color={"success"}
                                                variant={"contained"}
                                                onClick={() => setPreviewSong(true)}
                                                disabled={songTitle === '' || authors.length === 0 || (verses.length === 0 && choruses.length === 0)}>
                                            add song
                                        </Button>
                                </CenterDiv>
                            </div>
                    }
                </Fragment>

            </Dialog>
            <AddVerseModal
                addVerse={addVerse}
                setOpen={setAddVerseDialogState}
                open={addVerseDialogState}
                title={songTitle}
                verses={verses}
                verseNumber={verses.length + 1}/>

            <AddChorusModal addChorus={addChorus}
                            setOpen={setAddChorusDialogState}
                            open={addChorusDialogState}
                            title={songTitle}
                            chorusNumber={choruses.length + 1}/>

            <EditModal title={songTitle}
                       open={verseEditModalState}
                       setOpen={setVerseEditModalState}
                       type={VERSE}
                       number={selectedVerseNumber}
                       edit={editVerse} previous={verses[selectedVerseNumber - 1]}/>

            <EditModal title={songTitle}
                       open={chorusEditModalState}
                       setOpen={setChorusEditModalState}
                       type={CHORUS}
                       number={selectedChorusNumber}
                       edit={editChorus} previous={choruses[selectedChorusNumber - 1]}/>

            <Dialog open={previewSong} onClose={setPreviewSong}>
                <DialogTitle>{`Song preview for ${songTitle}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This is a preview showing the song information.. It should be used to verify correct song information
                    </DialogContentText>
                    <div style={{
                        marginTop: '1rem'
                    }}/>
                    <SongCard song={buildSongFromData()} preview/>
                </DialogContent>
                <DialogActions>
                    <Button color={"error"} onClick={() => setPreviewSong(false)}>Close</Button>
                    <Button color={"success"} onClick={createSong}>Create song</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default SongDialogContainer;