export class EventUpdateRequest {
    constructor(streamingLink, messageLink, men, women, children, pid) {
        this.pid = pid;
        this.streamingLink = streamingLink;
        this.messageLink = messageLink;
        this.men = men;
        this.women = women;
        this.children = children;
    }

    getJsonString = () => {
        return JSON.stringify(this)
    }

    buildFromEventDTO = (event) => {

        return new EventUpdateRequest(
            event.streamingLink, event.messageLink, event.men, event.women, event.children, event.pid
        );
    }
}


