import React, {useEffect} from "react";
import EventHeader from "../components/events/event-header";
import EventTable from "../components/events/event-table";
import {useDispatch, useSelector} from "react-redux";
import {fetchEventsAsync} from "../features/events/eventSlice";
import ErrorMessage from "../components/utils/error-message";
import {LOADING, SUCCESS} from "../httpClient";
import Loading from "../components/utils/loading";
import {useAuth} from "oidc-react";

const EventsPage = () => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const { events, status } = useSelector(state => state.events)

    useEffect(() => {
        if (auth && auth.userData) {
            dispatch(fetchEventsAsync(auth.userData.access_token))
        }
    }, [auth.userData, dispatch]);

    if (status === LOADING) {
        return <div style={{
            marginTop: '3rem'
        }}>
            <Loading message={'one moment please'}/>
        </div>
    }
    return <div>
        <EventHeader/>
        {
            status === SUCCESS ?
                <EventTable events={events ? events : []}/>
                :
                <ErrorMessage message={'Could not get events at the moment..please reload this page at a later time'}
                              variant={'h6'}
                              component={'div'}
                              action
                              actionMessage={'reload'}
                              performAction={() => window.location.reload()}/>
        }

    </div>
}

export default EventsPage;
